import React from 'react';
import './allCss/GameView.css';

import searchButton from '../../assets/searchButton.png';
import searchButton_hover from '../../assets/searchButton_hover.png';

class SearchBar extends React.Component {
    state = {
        myInput: '',
        searchImg: searchButton
    };

    onInputChanged = event => {
        this.setState({myInput: event.target.value});
    }

    onFormSubmited = event => {
        event.preventDefault();

        this.props.onFormSubmit(this.state.myInput);
    }

    render() {
        return (
            <div className="search-bar" 
            onMouseEnter={() =>this.setState({searchImg: searchButton_hover})}
                onMouseLeave={() => this.setState({searchImg: searchButton})}
            >
                <form  onSubmit={this.onFormSubmited}>
                    <input
                    className="input-text"
                    type="text"
                    value={this.state.myInput}
                    onChange={this.onInputChanged}
                    />
                </form>
                <img className="search-icon" alt='search'
                
                 src={this.state.searchImg} onClick={this.onFormSubmited}/>

            </div>
        );
    }
}

export default SearchBar;