import React from 'react';
import {connect} from 'react-redux';
import {fetchContactSection} from '../../actions';

import separator_main from '../../assets/svg_files/separator_main.svg'




import './allCss/ContactPage.css';


class ContactPage extends React.Component {

    componentDidMount () {
        this.props.fetchContactSection();
    }

    renderSeparator = () => {
        return (
            <div style={{display: '-webkit-flex',display: 'flex', padding: '10px 0 30px 0' }} >

                    <svg height="8px" width="100%">
                      <line x1="30%" y1="8px" x2="98%" y2="8px" style={{stroke:'#dc3847',strokeWidth:2}} />
                    </svg>
                    <embed className="separator"  alt='separator' src={separator_main}/>
                    <svg height="8px" width="100%">
                      <line x1="2%" y1="8px" x2="70%" y2="8px" style={{stroke:'rgb(220,56,71)',strokeWidth:2}} />
                    </svg>
                </div>
        )
    }

    render() {
        if(!this.props.contacts.length) {
            return (<div>Loading..</div>);
        }

        return (
            <div id="contact">
                    <div className="contact-title">{this.props.contacts[0].title}</div>
                    <div className="contact-description1">{this.props.contacts[0].description1}</div>
                    <div className="contact-description2">{this.props.contacts[0].description2}</div>
                    <div className="contact-question">{this.props.contacts[0].desc_question}</div>
                    {this.renderSeparator()}
            </div>
        )
    }
} 

const mapStateToProps = (state) => {
    return {
        contacts:  Object.values(state.contacts)
    }
  }

export default connect(mapStateToProps, {fetchContactSection})(ContactPage);