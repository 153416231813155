import React from 'react';
import "./App.css";

import history from '../history';


import  logo_svg from '../assets/svg_files/logo_svg.svg';





class HeaderPages extends React.Component {

    state = {
        isOpen: false,
        isHovered: false,
        isOvered: false,
        position: 'absolute',
        targetName: "",
        positionByName: 0,
        stillScrolling: false

    };


    handleMouseHover = (event) => {

        if(!this.state.isHovered && !this.state.isOvered) {
            this.setState({isHovered: true});
            this.timer = setInterval(() => {
                this.setState({isHovered: false, isOvered: true})
                clearInterval(this.timer);
            },300)

        } else {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.setState({isHovered: false, isOvered: false });
            


        }

    }

    toggleHoverState(state) {
        return {
          isHovered: !state.isHovered,
        };
      }
    
          

    clickOnHamburger = (event)  => {
        this.onClickMenu(event)

        if (!this.state.isOpen) {
            this.setState({isOpen: true});

         } else {
            
            
            this.setState({isOpen: false});

         }
    };


    openMobileMenu = () => {
            return (
                <div id= "nav-mobile"  className={this.state.isOpen? "open": ""}>
                    <div></div>
                    <div>
                        <a  onClick={this.clickOnHamburger}  id="item"><h3 name='about-company'>HOME</h3></a>
                    </div>
                    <div>
                        <a  onClick={this.clickOnHamburger}  id="item"><h3 name='about'>ABOUT</h3></a>
                    </div>
                    <div>
                        <a  onClick={this.clickOnHamburger}  id="item"><h3 name='gamePage'>GAMES</h3></a>
                    </div>
                    
                    {/* <div>
                        <a  onClick={this.clickOnHamburger} id="item"><h3 name='news'>NEWS</h3></a>
                    </div> */}
                    <div>
                        <a  onClick={this.clickOnHamburger} id="item"><h3 name='contact'>CONTACT</h3></a>
                    </div>
                    
                </div>
            )
    }

    renderHamburger = () => {
        return (
                <div id="hamburger" className={!this.state.isOpen ? 'right menu': 'right menu open'} onClick={this.clickOnHamburger} >
                    <a>
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>

                </div>
        )
    }

    onClickMenu = (event) => {
        const name = event.target.getAttribute('name');
        if (this.state.targetName !== name ||  window.scrollY !== this.state.positionByName) {
            
            
            if (!this.state.stillScrolling) {

                history.push('/');
                this.setState({targetName: name});
                this.timer = setInterval(() => {
                    if(name != null && document.getElementById(name)) {
                        let ofsets = document.getElementById(name).offsetTop;
                        this.setState({positionByName: ofsets - 80})
                        window.scrollTo({top: ofsets - 80,left: 0 , behavior: 'smooth'});
                    }
                    clearInterval(this.timer);
                },300)
                this.setState({stillScrolling: true});
            }
        }
        
        
    }

    componentDidMount = () => {
        window.addEventListener('scroll', () => {
            if (window.scrollY === this.state.positionByName) {
                this.setState({stillScrolling: false});
            }
            if (window.scrollY < 300) {
                document.getElementById('logo').style.display = null;
                document.getElementsByClassName('navigate')[0].style.height = null;
                document.getElementsByClassName('header-panel')[0].style.height = null;

                document.getElementsByClassName('full-title')[0].style.top = null;
                document.getElementById('menu').style.top = null;




                document.getElementsByClassName('navigate')[0].style.margin = null;

                document.getElementsByClassName('navigate')[0].style.position = "absolute";
                document.getElementsByClassName('navigate')[0].style.opacity = null;
                document.getElementsByClassName('navigate')[0].style.animationName = null;
                document.getElementsByClassName('navigate')[0].style.animationIterationCount = null;
                document.getElementsByClassName('navigate')[0].style.animationTimingFunction = null;
                document.getElementsByClassName('navigate')[0].style.animationDuration = null;
            } else {
                if (document.getElementsByClassName('navigate')[0].style.position !== 'fixed') {
                    document.getElementsByClassName('navigate')[0].style.position = "fixed";

                    
                    document.getElementById('logo').style.display = "none";
                    document.getElementById('menu').style.top = "60%";
                    document.getElementsByClassName('full-title')[0].style.top = "50%";

                    document.getElementsByClassName('navigate')[0].style.height = "80px";
                    document.getElementsByClassName('header-panel')[0].style.height = "80px";

                    document.getElementsByClassName('navigate')[0].style.margin = "auto";

                    // document.getElementsByClassName('navigate')[0].style.marginLeft = "25px";



                    document.getElementsByClassName('navigate')[0].style.opacity = 1;
                    document.getElementsByClassName('navigate')[0].style.animationName = 'fadeInOpacity';
                    document.getElementsByClassName('navigate')[0].style.animationIterationCount = "1";
                    document.getElementsByClassName('navigate')[0].style.animationTimingFunction = "ease-in";
                    document.getElementsByClassName('navigate')[0].style.animationDuration = ".25s";
                }
            }
        })
    }

    render() {

        return (
                <div className='navigate' style={{position: this.state.position}} >

                    <div className= "">
                        <div  onClick={this.onClickMenu}  >
                            <img name="home" id="logo" alt='' src={logo_svg}/>
                        </div>
                        <div className="header-panel">

                            <div name="home" onClick={() => {history.push('/');window.scrollTo({top: 0,left: 0 , behavior: 'smooth'}); }}  >
                                
                                <div name="home" className="full-title" >
                                    <div id="title-company" >{`Peter & Sons`}</div>
                                    {/* <div id="title-part">{`Quality and Tradition`}</div> */}

                                </div>
                            </div>
                            <div id="menu" className="right menu">
        
                                <div  onClick={this.onClickMenu} className="" >
                                    <div name='about-company' id="menu-item">Home</div>
                                </div>
                                <div onClick={this.onClickMenu}  className='' >
                                    <div name='about' id="menu-item">About</div>
                                </div>
                                <div  onClick={this.onClickMenu}  className='' >
                                    <div name='gamePage' id="menu-item">Games</div>
                                </div>
                                {/* <div  onClick={this.onClickMenu}  className='' >
                                    <div name='news' id="menu-item">News</div>
                                </div> */}
                                <div onClick={this.onClickMenu}  className='' >
                                    <div name='contact' id="menu-item">Contact</div>
                                </div>
                                
                                
                            </div>
                        {this.renderHamburger()}
                        </div>
                        
                    </div>
                        {this.openMobileMenu()}
                </div>
        )
    }
};

export default HeaderPages;


