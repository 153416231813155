import React from 'react';
import './allCss/Home.css'

import banner1 from '../../assets/Sheriff Colt_/banner.png';
import banner1_mob from '../../assets/Sheriff Colt_/banner_mobil.png';

import banner2 from '../../assets/Rome/banner.png';
import banner2_mob from '../../assets/Rome/banner_mobil_2.png';

import banner3 from '../../assets/Fight club/banner.png';
import banner3_mob from '../../assets/Fight club/banner_mobil_3.png';

import banner4 from '../../assets/D`Cirque/banner.png';
import banner4_mob from '../../assets/D`Cirque/banner_mobil.png';

import banner5 from '../../assets/Johnon/banner.png';
import banner5_mob from '../../assets/Johnon/banner_mobil.png';

import banner6 from '../../assets/Robin/banner.png';
import banner6_mob from '../../assets/Robin/banner_mobil.png';


import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 
 

class HomePage extends React.Component {

    state = {banner6: banner6, banner5: banner5, banner4: banner4, banner1: banner1, banner2: banner2, banner3: banner3};

    componentDidMount () {
        if (window.innerWidth <= 650) {
            this.setState({banner6: banner6_mob, banner5: banner5_mob, banner4: banner4_mob, banner1: banner1_mob, banner2: banner2_mob, banner3: banner3_mob})
        } else {
            this.setState({banner6: banner6, banner5: banner5,banner4: banner4, banner1: banner1, banner2: banner2, banner3: banner3})

        }


        window.addEventListener('resize', ()=> {
            if (window.innerWidth <= 650) {
                this.setState({banner6: banner6_mob, banner5: banner5_mob, banner4: banner4_mob, banner1: banner1_mob, banner2: banner2_mob, banner3: banner3_mob})
            } else {
                this.setState({banner6: banner6, banner5: banner5,banner4: banner4, banner1: banner1, banner2: banner2, banner3: banner3})
    
            }
        })
    }


    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 650,
                    settings: {
                        arrows: false
                    }
                }
            ]
        }

        return (
            <div id="home">

                <Slider className="slider-banner"  {...settings}>
                    <div >
                        <img className="image-banner"  alt="banner" src={this.state.banner6}/>
                    </div>
                    <div >
                        <img className="image-banner"  alt="banner" src={this.state.banner5}/>
                    </div>
                    <div >
                        <img className="image-banner"  alt="banner" src={this.state.banner4}/>
                    </div>
                    <div >
                        <img className="image-banner"  alt="banner" src={this.state.banner1}/>
                    </div>
                    <div >
                        <img className="image-banner"  alt="banner" src={this.state.banner2}/>
                    </div>
                    <div >
                        <img className="image-banner"  alt="banner" src={this.state.banner3}/>
                    </div>

                        {/* <ParallaxBanner
                            className= "your-class" 
                            layers={[
                                {
                                    image: banner,
                                    amount: .1,
                                },
                                
                            ]}
                            style={{
                                marginTop: '20px',

                                width: '100%',
                                // height: '350px',
                                'z-index': 2
                            }}

                        >
                        </ParallaxBanner> */}

                </Slider>

            </div>
                    
        )
    }

}

export default HomePage;