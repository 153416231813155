import React from 'react';
import {connect} from 'react-redux';

import './allCss/NewsPage.css';

import {fetchNews} from '../../actions';
import Footer from '../Footer';

class NewsView extends React.Component {

    componentDidMount () {
        this.props.fetchNews(this.props.match.params.id);
    }

    renderTitle = () => {
        return (
            <div>
                <div className="titlePart">
                    <div className="news-page-title">
                        {this.props.game.newsPage_title}
                    </div>
                </div>
                <div className="news-description1-section">
                    <img className="new-page-image" alt="" src={this.props.game.gameIcon}/>
                    <div className="news-desc-text">
                        <div className="news-title-text">{this.props.game.desc1_title}</div>
                        <div className="news-texts">{this.props.game.desc1_text1}</div>
                        <div className="news-texts">{this.props.game.desc1_text2}</div>
                        <div className="news-texts">{this.props.game.desc1_text3}</div>
                    </div>

                </div>
            </div>
        )
    }

    renderAboutPartner = () => {
        return (
            <div className="news-about-part">
                <div className="news-about-title">{this.props.game.partner_title}</div>
                <div className="news-about-desc">{this.props.game.partner_desc1}</div>
                <div>
                    <div className="news-about-desc">{this.props.game.partner_desc2}</div>
                    <div className="news-about-desc">{this.props.game.partner_email}</div>
                    <div className="news-about-desc">{this.props.game.partner_location}</div>
                </div>

            </div>
        )
    }

    renderAboutCompany = () => {
        return (
            <div className="news-about-company">
                <div className="news-about-part">

                    <div className="news-about-title">{this.props.game.company_title}</div>
                    <div className="news-about-desc">{this.props.game.company_desc}</div>
                </div>

            </div>
        )
    }

    

    render() {
        console.log(this.props)

        if (!this.props.game) {
            return <div>Loading..</div>
        }

        return (
            <div className="news-page">
                {this.renderTitle()}
                {this.renderAboutPartner()}
                {this.renderAboutCompany()}
                <div style={{'background-color': '#ebeeef'}}>

                    <Footer/>
                </div>
                
            </div>
            )
    }
}

const mapStateToProps = (state, ownProp) => {
    return {
        game: state.news[ownProp.match.params.id]
    }
}

export default connect(mapStateToProps, {fetchNews})(NewsView);