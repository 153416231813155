import React from 'react';
import './allCss/GameCard.css';

import {Link} from 'react-router-dom';





class NewsCard extends React.Component {

        changeScroll = () => {
            window.scrollTo({
                top: 0,
                left: 0
              });
        }

    render () {
            if (!this.props.news) {
                return <div>Loading..</div>
            }

        return (
                <Link to={`/news/${this.props.news.id}`}  onClick={this.changeScroll} id="gameCard"  className="ui raised link card ">
                <div  className="image ">
                        <img className='' style={{height: 250}} alt='' src={this.props.news.url}/>
                </div>
                <div className="content">
    
                        <div  ><h3 className="header-title">{this.props.news.title}</h3></div>
                    <p className="description">
                    {this.props.news.description}
                    </p>
                        <div className="view-more" >
                        View More +
                        </div>
                </div>
                <div className="extra content">
                    <i className="right floated html5 icon"></i>
                </div>
                
                </Link>
    
        )
    }
}

export default NewsCard;