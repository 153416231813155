import React from 'react';
import {connect} from 'react-redux';
import './allCss/AboutCompanyPage.css';
import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {fetchAboutPage} from '../../actions'

import separator_main from '../../assets/svg_files/separator_main.svg'



class AboutCompanyPage extends React.Component {

    componentDidMount = () => {
        this.props.fetchAboutPage();
    }

    createPartnersSection = () => {
        const settings = {
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: false,
            responsive: [
                {
                    breakpoint: 4000,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      infinite: true,
                    //   dots: true
                    }
                },
                {
                    breakpoint: 850,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                    //   dots: true
                    }
                }
            ]
        }

            return (
                    <Slider className="slider-partners" {...settings}>
                            <div>
                                <div className="section-1">
                                    <div id="h1-title2" style={{paddingTop: '30px'}}>{this.props.abouts[0].partnerDdescription1}</div>
                                    <img className="partner-logo1" alt="" src={this.props.abouts[0].partnerLogo1}/>
                                </div>
                            </div>
                            <div>
                                <div className="section-1">
                                    <div id="h1-title2">{this.props.abouts[0].partnerDdescription2}
                                    <img className="partner-logo1" alt="" src={this.props.abouts[0].partnerLogo2}/></div>
                                    
                                </div>
                            </div>        
                    </Slider>

                    
    
            );
    }

    render() {
        if (!this.props.abouts.length) {
            return <div>Loading...</div>
        }

        return (
            <div id="about-company"> 
    
                <div id="about-description">
                    <div className="desc1-title">{this.props.abouts[0].description1}</div>
                    <div className="desc2-title">{this.props.abouts[0].description2}</div>
                    <div className="desc2-title">{this.props.abouts[0].description3}</div>
                </div>
                <div style={{display: '-webkit-flex' ,display: 'flex', padding: '30px 0 20px 0'}} >

                    <svg height="8px" width="100%">
                      <line x1="30%" y1="8px" x2="98%" y2="8px" style={{stroke:'#dc3847',strokeWidth:2}} />
                    </svg>
                    <embed className="separator"  alt='separator' src={separator_main}/>
                    <svg height="8px" width="100%">
                      <line x1="2%" y1="8px" x2="70%" y2="8px" style={{stroke:'rgb(220,56,71)',strokeWidth:2}} />
                    </svg>
                </div>

                {this.createPartnersSection()}
    
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        abouts:  Object.values(state.aboutPage)
    }
}


export default connect(mapStateToProps, {fetchAboutPage})(AboutCompanyPage);