import _ from 'lodash';

import {
    FETCH_NEWS_SECTION,
    FETCH_NEWS,

} from '../actions/Types';

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_NEWS_SECTION:
            return {...state, ..._.mapKeys(action.payload, 'id')};
        case FETCH_NEWS:
            return {...state, [action.payload.id]: action.payload};
        default:
            return state;
    }
}