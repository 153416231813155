import React from 'react';
import { connect } from 'react-redux';
import { fetchGame, fetchGames } from '../../actions';
import Footer from '../Footer'
// import "./allCss/GameView.css"
import "./allCss/NewGameView.css"

import { ParallaxBanner} from 'react-scroll-parallax';

import separator_main from '../../assets/svg_files/separator_main.svg'

import GameCard from './GameCard';


import Slider from 'react-slick';


class GameView extends React.Component {
    
    state = {banner: null, loading: false , list: [], timer: 5500}

    renderButton = () => {
        if (this.props.game.gameUrl.length > 0) {

            return(
                    <div className="tryButton" onClick={() => window.open(
                        this.props.game.gameUrl, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=500,height=1000")
                        }>
                        TRY NOW
                    </div>
            )
        }

        return;
    }

    
    componentDidMount() {
        
        this.props.fetchGame(this.props.match.params.id);
        this.props.fetchGames();
        

    
    
            window.addEventListener('resize', ()=> {
                if (window.innerWidth <= 650) {
                    this.setState({banner: this.props.game.bannerMob})
                } else {
                    this.setState({banner: this.props.game.banner})
        
                }
            })
    }

    componentWillUpdate(np) {
        const { match } = this.props;
        const prevPostId = match.params.id;
        const nextPostId = np.match.params.id;

        /* ///////////////////   test ////////////////// */
        // if (this.state.list.indexOf(prevPostId) === -1) {

        //     this.setState( state => {
        //         const list = state.list.push(prevPostId);
                
        //         return list;
        //     })
        // }

        if(nextPostId && prevPostId !== nextPostId){
            // console.log('indexOF      = ',this.state.list.indexOf(nextPostId));
            // if (this.state.list.indexOf(nextPostId) >= 0) {
            //     this.setState({timer: 1500})
            // } else {
            //     this.setState({timer: 5500})
                
            // }
            // this.setState({loading: true});
            // do something
        //   console.log('loading', this.state.loading);

        }
      }

    componentWillReceiveProps = (newProps) => {
        
        // this.demoAsyncCall().then(() => {
        //     this.setState({ loading: false });
        //     console.log('mtaaaaaaaaaav', this.state.loading);
    
        // });
        // console.log('mtaaaaaav');
        if (newProps.game) {
            if (window.innerWidth <= 650) {
                this.setState({banner: newProps.game.bannerMob})
            } else {
                this.setState({banner: newProps.game.banner})
    
            }
        }
    }

    demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), this.state.timer));
      }

    renderDescription = () => {
        return (
            <div  className="desc-part" >
                <div className="game-images-section">
                    <img className="game-image-preview" alt="preview" src={this.props.game.gamePreview}/>
                    {this.renderButton()}

                </div>
                <div className="game-desc-section">
                    <div className="game-desc-title">{this.props.game.gameTitle}</div>
                    <div className="game-desc-text" style={{color: '#dc3847'}}>{this.props.game.descriptionTitle}</div>
                    <div className="game-desc-text">{this.props.game.gameDescription}</div>
                </div>
                
            </div>
        )
    }

    createSlidePics = () => {
        return this.props.game.gameScreens.map(image => {
            
            return (

                <div>
                    <img className="slider-image" src={image} alt="screen"/>
                </div>
            )
        })
        
    }

    renderSlider = () => {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            variableWidth: true,
            
            responsive: [
                
                
                {
                    breakpoint: 650,
                    settings: {
                      arrows: false


                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                    arrows: false, 

                      slidesToShow: 1,
                      slidesToScroll: 1,
                      variableWidth: false,
                      centerMode: false,


                    }
                },

            ]
            
        }

        return (
            <div >
                <Slider className="slide-show" {...settings}>
                    {this.createSlidePics() }
                </Slider>
                {this.renderSeparator()}

            </div>
        )
    }

    renderSeparator = () => {
        return (
            <div style={{display: '-webkit-flex', display: 'flex', padding: '10px 0 30px 0'}} >

                    <svg height="8px" width="100%">
                      <line x1="30%" y1="8px" x2="98%" y2="8px" style={{stroke:'#dc3847',strokeWidth:2}} />
                    </svg>
                    <embed className="separator"  alt='separator' src={separator_main}/>
                    <svg height="8px" width="100%">
                      <line x1="2%" y1="8px" x2="70%" y2="8px" style={{stroke:'rgb(220,56,71)',strokeWidth:2}} />
                    </svg>
                </div>
        )
    }

    renderFeatures = () => {
        return (
            <div className='features-section'>
                <div style={{position: "relative"}}>
                    <div id="info" className="info-class">
                        <div className="features-title">{this.props.game.gameFeatures}</div>
                    
                        <div id="info2" className="info" style={{'margin-top': '80px'}}>
                            <div className="specs" id="info-specs">
                                
                                <div className="info-col">
                                    <div className="info-title">{this.props.game.gameType}</div>
                                    <div className="info-description">{this.props.game.gameTypeValue}</div>
                                </div>
                                <div className="info-col">
                                    <div className="info-title">{this.props.game.fullSize}</div>
                                    <div className="info-description">{this.props.game.fullSizeValue}</div>
                                </div>
                                <div className="info-col">
                                    <div className="info-title">{this.props.game.rtp}</div>
                                    <div className="info-description">{this.props.game.rtpValue}</div>
                                </div>
                                <div className="info-col">
                                    <div className="info-title">{this.props.game.language}</div>
                                    <div className="info-description">{this.props.game.languageValue}</div>
                                </div>
                                <div className="info-col">
                                    <div className="info-title">{this.props.game.productSource}</div>
                                    <div className="info-description">{this.props.game.productSourceValue}</div>
                                </div>
                                <div className="info-col">
                                    <div className="info-title">{this.props.game.sesionVolatility}</div>
                                    <div className="info-description">{this.props.game.sesionVolatilityValue}</div>
                                </div>
                                
                                <div className="info-col">
                                    <div className="info-title">{this.props.game.compatibleWith}</div>
                                    <div className="info-compatible">
                                        <div style={{paddingRight: '20px', paddingBottom: '15px'}} className="info-description">{this.props.game.iOs}</div>
                                        <div style={{paddingRight: '20px' , paddingBottom: '15px'}} className="info-description">{this.props.game.ANDROID}</div>
                                        <div style={{paddingRight: '20px', paddingBottom: '15px'}} className="info-description">{this.props.game.WINDOWS}</div>
                                        <div style={{paddingRight: '20px', paddingBottom: '15px', 'white-space':'nowrap'}} className="info-description">{this.props.game.MAC}</div>
                                        <div style={{paddingRight: '20px'}} className="info-description">{this.props.game.HTML5}</div>
                                    </div>
                                </div>
                                <div className="info-col"></div>
                                <div className="info-col">
                                    <div className="info-title">{this.props.game.maxWin}</div>
                                    <div className="info-description">{this.props.game.maxWinValue}</div>
                                </div>
                            </div>
                                <img className="promo-image" alt="promo" src={this.props.game.gamePromoIcon}/>
                        </div>
                        
                    </div>
                </div>

            </div>
        )
    }

    randomValuesCounter = () => {
        let arr = [parseInt(this.props.match.params.id)];
        while(arr.length < 4){
            let rand = Math.floor(Math.random() * this.props.games.length) ;
            if(arr.indexOf(rand) === -1 ) {
                arr.push(rand);
            } 
        }

        return arr;
        
    }

    otherGamesSection = () => {
        const randomValues = this.randomValuesCounter();
        const settings = {
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 3,
            slidesToScroll: 1,
            
            responsive: [
                {
                    breakpoint: 4000,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 700,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      arrows: false

                    },
                }
            ]
            
        }

            return (

                    <Slider className="other-sliders-section" {...settings}>
                            <div className="col-4 " >
                                    <GameCard game={this.props.games[randomValues[1]]} />
                            </div>
                            <div className="col-4 " >
                                    <GameCard game={this.props.games[randomValues[2]]} />
                            </div>
                            <div className="col-4 " >
                                    <GameCard game={this.props.games[randomValues[3]]} />
                            </div>
                    </Slider>
            );
            
    }

    loadingScene = () => {
        if (this.state.loading) {

            return (
                (
                    <div className="loading-scene">
                        <div style={{position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>

                            <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>)
            )
        }
        window.scrollTo(0, 0);

        return <div></div>
    }


    

    render() {

        if (!this.props.game && !this.props.games.length ) {
            return (
            <div>
                <div>Loading...</div>
                
            </div>
                
            )

        } 

        // if (this.state.loading) { 
        //     return (
        //         <div className="loading-scene">
        //         <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        //         </div>)
        // }
        

        return (
            <div className="game-view-main">
                {this.loadingScene()}
                <img className="game-banner"  alt="banner" src={this.state.banner}/>
                <div  className="game-view">
                    {this.renderDescription()}
                    {this.renderSlider()}
                    {this.renderFeatures()}
                    <div className="otherGames-section">
                        <div className="otherGames-title">OTHER PROJECTS</div>

                        <div id="games" className="otherGames-row">

                            {this.otherGamesSection()}
                        </div>
                    </div>
                    <div style={{backgroundColor: '#ebeeef'}}>

                        {this.renderSeparator()}
                    </div>
                </div>
                

                <div style={{'background-color': '#ebeeef'}}>

                    <Footer/>
                </div>
            </div>

        )
    }
}



const mapStateToProps = (state, ownProp) => {
    
            return {
                game: state.games[ownProp.match.params.id],
                games: Object.values(state.games)
            }
}

export default connect(mapStateToProps, { fetchGame, fetchGames })(GameView);