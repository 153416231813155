import _ from 'lodash';

import {
    FETCH_ABOUT,
} from '../actions/Types';

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_ABOUT:
            return {...state, ..._.mapKeys(action.payload, 'id')};
        default:
            return state;
    }
}