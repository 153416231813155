import React from 'react';
import {connect} from 'react-redux';
import GameCard from './GameCard';
import SearchBar from './SearchBar';
import './allCss/GameView.css';

import {fetchGames} from '../../actions';

import bespokeImg from '../../assets/bespoke.png';
import bespoke_hoverImg from '../../assets/bespoke_hover.png';
import heartImg from '../../assets/heart.png';
import heart_hoverImg from '../../assets/heart_hover.png';
import allGamesImg from '../../assets/allGames.png';
import allGames_hoverImg from '../../assets/allGames_hover.png';
import settingImg from '../../assets/setting_button.png';
import setting_hoverImg from '../../assets/setting_button_hover.png';



class GamesPage extends React.Component  {

    state = {
        filter: 'allGames',
        search: '',
        bespokeImg: bespokeImg,
        heartImg: heartImg,
        allGamesImg: allGames_hoverImg,
        settingImg: settingImg

};

   componentDidMount () {
       this.props.fetchGames();

       
   }


   searchingTerm = name => {
    for (const char of name) {
        if (char === this.state.search.charAt(0)) {
            if(name.substring(name.indexOf(char), name.indexOf(char) + this.state.search.length)  === this.state.search) {
                return true;
            }
        }
    }
   }


   renderList () {
       const games = this.props.games.slice(0).reverse();
    return games.map(game => {


        if (this.searchingTerm(game.name)) {
            return (
                <div className="col-4 " key={game.id}>
                        <GameCard game={game} />
                </div>
            );
        }

            switch (this.state.filter ) {
                
                case 'popular':
                    if(game.popularity) {
    
                        return (
                            <div className="col-4 " key={game.id}>
                                    <GameCard game={game} />
                            </div>
                
                        );
                    }
                    break;
                case 'featured':
                    if(game.featured) {
    
                        return (
                            <div className="col-4 " key={game.id}>
                                    <GameCard game={game} />
                            </div>
                
                        );
                    }
                    break;
                case 'allGames':
                    return (
                        <div className="col-4 " key={game.id}>
                                <GameCard game={game} />
                        </div>
            
                    );
                case 'setting':
                    if(game.setting) {
    
                        return (
                            <div className="col-4 " key={game.id}>
                                    <GameCard game={game} />
                            </div>
                
                        )
                    }
                    break;
                default:
                    
                       
            }
           

    });
   }

   onTermSubmit = term => {
       if(term) {

           this.setState({search: term, filter: ''});
       }
   }

   clickedOnSection = (section) => {
       if (window.innerWidth < 700) {

           document.getElementById(`filter-${section}`).style.display = 'block';
           document.getElementById(`filter-img-${section}`).style.display = 'none';
           window.setTimeout(() => {
            document.getElementById(`filter-${section}`).style.display = null;
           document.getElementById(`filter-img-${section}`).style.display = null;
           }, 1000)
       }

       switch (section) {
           case 1:
            this.setState({allGamesImg: allGamesImg, heartImg: heartImg, bespokeImg: bespokeImg, settingImg: settingImg})
                this.setState({allGamesImg: allGames_hoverImg});
                break;
            case 2:
                this.setState({allGamesImg: allGamesImg, heartImg: heartImg, bespokeImg: bespokeImg, settingImg: settingImg})

                this.setState({heartImg: heart_hoverImg});
                break;
            case 3:
                this.setState({allGamesImg: allGamesImg, heartImg: heartImg, bespokeImg: bespokeImg, settingImg: settingImg})

                this.setState({bespokeImg: bespoke_hoverImg})
                break;
            case 4:
                this.setState({allGamesImg: allGamesImg, heartImg: heartImg, bespokeImg: bespokeImg, settingImg: settingImg})

                this.setState({settingImg: setting_hoverImg})
                break;
            
            default:

       }

   }

   createFilterPanel = () => {
       return (
           <div className="sticky-filter">
               <div className={this.state.filter === 'allGames'? `filter-sections active` : `filter-sections`} 
                 onClick={() => {this.setState({filter: 'allGames'});this.clickedOnSection(1) }}
                 onMouseEnter={() =>this.setState({allGamesImg: allGames_hoverImg})}
                 onMouseLeave={() => {if (this.state.filter !== 'allGames') {this.setState({allGamesImg: allGamesImg})}}}
                 >
                    <img id="filter-img-1" alt='' src={this.state.allGamesImg} className="icon-img" />
                    <div id="filter-1" className="filter-text"><div>ALL GAMES</div></div>
                </div>
                
                <div className={this.state.filter === 'popular'? `filter-sections active` : `filter-sections`}
                 onClick={() => {this.setState({filter: 'popular'}); this.clickedOnSection(2)}}
                 onMouseEnter={() =>this.setState({heartImg: heart_hoverImg})}
                 onMouseLeave={() => { if (this.state.filter !== 'popular') {this.setState({heartImg: heartImg})}}}
                 >
                    <img id="filter-img-2" alt='' src={this.state.heartImg} className="icon-img" />
                    <div id="filter-2" className="filter-text"><div>{`P & S`}</div></div>
                </div>
                <div className={this.state.filter === 'featured'? `filter-sections active` : `filter-sections`}
                 onClick={() => {this.setState({filter: 'featured'}); this.clickedOnSection(3)}}
                 onMouseEnter={() =>this.setState({bespokeImg: bespoke_hoverImg})}
                 onMouseLeave={() => { if (this.state.filter !== 'featured') {this.setState({bespokeImg: bespokeImg})}}}
                 >
                    <img id="filter-img-3" alt='' src={this.state.bespokeImg} className="icon-img" />
                    <div id="filter-3" className="filter-text"><div>BESPOKE</div></div>
                </div>
                <div className={this.state.filter === 'setting'? `filter-sections active` : `filter-sections`}
                 onClick={() => {this.setState({filter: 'setting'}); this.clickedOnSection(4)}}
                 onMouseEnter={() =>this.setState({settingImg: setting_hoverImg})}
                 onMouseLeave={() => {if (this.state.filter !== 'setting') {this.setState({settingImg: settingImg})}}}
                 >
                    <img id="filter-img-4" alt='' src={this.state.settingImg} className="icon-img" />
                    <div id="filter-4" className="filter-text"><div>IN DEVELOPMENT</div></div>
                </div>
                {/* <SearchBar className="search-section" onFormSubmit={this.onTermSubmit}/> */}
           </div>
       )
   }


    render () {
        if(!this.props.games.length) {
            return <div>Loading...</div>
        }

        return (
            <div>
                <div id="gamePage" >
                    <div id="games-title">
                        <div>
                            <h1 id="h1-games-title">OUR GAMES</h1>
                        </div>
                        <div className="filter-panel">
                            {this.createFilterPanel()}
                        </div>
                    </div>
                    <div id="games" className="row">
                        {this.renderList()}
                    </div>
                    {/* <Footer/> */}

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return  {
        games: Object.values(state.games),
    }
}

export default connect(mapStateToProps, {fetchGames})(GamesPage);
