import React from 'react';
import {connect} from 'react-redux';
import '../HeaderItems/allCss/About.css'

import {fetchAboutDetails} from '../../actions';

class CompanyPage extends React.Component {

  componentDidMount () {
    this.props.fetchAboutDetails();
  }

  renderList = () => {
    return this.props.abouts.map(about => {

      return this.renderGroup(about)
  });
  }

  renderGroup = (about) => {
    return (
        <div key={about.id} id="block">
          <img className="block-logo" alt="logo"  src={about.image}/>
          <div className="block-texts">
            <div id="block-title">{about.title} </div>
            <div id="block-description">
              {about.description1}
            </div>
            <div id="block-description">
              {about.description2}
            </div>
            <div id="block-description">
              {about.description3}
            </div>
          </div>
        </div>
    )
}

    render() {
      return (
          <div id="about" >
            <div style={{width: '100%', backgroundColor: '#ebeeef'}}>
              {this.renderList()}

            </div>
          </div>
      )
    }
}

const mapStateToProps = (state) => {
  return {
      abouts:  Object.values(state.abouts)
  }
}

export default connect(mapStateToProps, {fetchAboutDetails})(CompanyPage);