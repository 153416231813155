// import axios from 'axios'import { objectExpression } from '@babel/types';

// export default axios.create({
//     baseURL: 'http://localhost:3001'
// });


import skull from '../assets//pirateGame/skull.png' ;
// import saloon_background from '../assets/saloon-background.png' ;
import ps_logo_white from '../assets/ps_logo_white.png';


import leander_logo from "../assets/partners/leander_logo.png";
import microgaming_logo from "../assets/partners/microgaming-logo.png";
import qTech_logo from "../assets/partners/qTech_logo.png";
import quick_fire from "../assets/partners/quick_fire.png";
import solid_gaming from "../assets/partners/solid_gaming.png";
import casumo_logo from "../assets/partners/casumo_logo.png";
import mighty_finger_logo from "../assets/partners/mighty-finger.png";


import aboutInspiration from "../assets//svg_files/inspirational_1.svg"
import aboutProfessional from "../assets/svg_files/professional_1.svg"
import aboutCreative from "../assets/svg_files/creative_1.svg"


import aboutPng from "../assets/aboutPng.png";

// ///////////////////////////////////ABES SALOON////////////////////
import saloonBanner from '../assets/Abes Saloon/banner.png';
import saloonBannerMob from '../assets/Abes Saloon/banner_mobil_6.png';
import saloon_preview from '../assets/Abes Saloon/preview.png';
import saloon_icon from '../assets/Abes Saloon/icon.png';
import saloon_promo from '../assets/Abes Saloon/promo.png';
import saloon1 from '../assets/Abes Saloon/Screenshots/1.png';
import saloon2 from '../assets/Abes Saloon/Screenshots/2.png';
import saloon3 from '../assets/Abes Saloon/Screenshots/3.png';
import saloon4 from '../assets/Abes Saloon/Screenshots/4.png';
import saloon5 from '../assets/Abes Saloon/Screenshots/5.png';
import saloon6 from '../assets/Abes Saloon/Screenshots/6.png';
import saloon7 from '../assets/Abes Saloon/Screenshots/7.png';
// ///////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////CALL OF KORA////////////////////
import koraBanner from '../assets/Call Of Kora/banner.png';
import koraBannerMob from '../assets/Call Of Kora/Banner-Mobile.png';
import kora_preview from '../assets/Call Of Kora/preview.png';
import kora_icon from '../assets/Call Of Kora/icon.png';
import kora_promo from '../assets/Call Of Kora/promo.png';
import kora1 from '../assets/Call Of Kora/Screenshot/1.png';
import kora2 from '../assets/Call Of Kora/Screenshot/2.png';
import kora3 from '../assets/Call Of Kora/Screenshot/3.png';
import kora4 from '../assets/Call Of Kora/Screenshot/4.png';
import kora5 from '../assets/Call Of Kora/Screenshot/5.png';
import kora6 from '../assets/Call Of Kora/Screenshot/6.png';
// ///////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////PIRATE////////////////////
import pirateBanner from '../assets/pirateGame/banner.png';
import pirateBannerMob from '../assets/pirateGame/banner_mobil.png';
import pirates_preview from '../assets/pirateGame/preview.png';
import pirates_icon from '../assets/pirateGame/icon.png';
import pirates_promo from '../assets/pirateGame/promo.png';
import pirate1 from '../assets/pirateGame/screenshots/1.png';
import pirate2 from '../assets/pirateGame/screenshots/3.png';
import pirate3 from '../assets/pirateGame/screenshots/4.png';
import pirate4 from '../assets/pirateGame/screenshots/5.png';
import pirate5 from '../assets/pirateGame/screenshots/14.png';
// ///////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////BOOK OF SETH////////////////////
import bookBanner from '../assets/Book of Seth/banner.png';
import bookBannerMob from '../assets/Book of Seth/banner_mobil_5.png';
import book_preview from '../assets/Book of Seth/preview.png';
import book_icon from '../assets/Book of Seth/icon.png';
import book_promo from '../assets/Book of Seth/promo.png';
import book1 from '../assets/Book of Seth/screenshot/B1.png';
import book2 from '../assets/Book of Seth/screenshot/B2.png';
import book3 from '../assets/Book of Seth/screenshot/B3.png';
import book4 from '../assets/Book of Seth/screenshot/B4.png';
import book5 from '../assets/Book of Seth/screenshot/B5.png';
// ///////////////////////////////////////////////////////////////////////////////// 
///////////////////////////////////FIGHT CLUB////////////////////
import fightBanner from '../assets/Fight club/banner.png';
import fightBannerMob from '../assets/Fight club/banner_mobil.png';
import fight_preview from '../assets/Fight club/preview.png';
import fight_icon from '../assets/Fight club/icon.png';
import fight_promo from '../assets/Fight club/promo.png';
import fight1 from '../assets/Fight club/Screenshots_/0.png';
import fight2 from '../assets/Fight club/Screenshots_/2.png';
import fight3 from '../assets/Fight club/Screenshots_/3.png';
import fight4 from '../assets/Fight club/Screenshots_/4.png';
import fight5 from '../assets/Fight club/Screenshots_/5.png';
/////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////JOHNAN LEGENDARIAN////////////////////
import johnonBanner from '../assets/Johnon/banner.png';
import johnonBannerMob from '../assets/Johnon/banner_mobil.png';
import johnon_preview from '../assets/Johnon/preview.png';
import johnon_icon from '../assets/Johnon/icon.png';
import johnon_promo from '../assets/Johnon/promo.png';
import johnon1 from '../assets/Johnon/screenshot/J1.png';
import johnon2 from '../assets/Johnon/screenshot/J2.png';
// ///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////JOHNAN LEGENDARIAN////////////////////
import robinBanner from '../assets/Robin/banner.png';
import robinBannerMob from '../assets/Robin/banner_mobil.png';
import robin_preview from '../assets/Robin/preview.png';
import robin_icon from '../assets/Robin/icon.png';
import robin_promo from '../assets/Robin/promo.png';
import robin1 from '../assets/Robin/screenshot/1.png';
import robin2 from '../assets/Robin/screenshot/2.png';
import robin3 from '../assets/Robin/screenshot/3.png';
// ///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////ROME////////////////////
import romeBanner from '../assets/Rome/banner.png';
import romeBannerMob from '../assets/Rome/banner_mobil_2.png';
import rome_preview from '../assets/Rome/preview.png';
import rome_icon from '../assets/Rome/icon.png';
import rome_promo from '../assets/Rome/promo.png';
import rome1 from '../assets/Rome/screenshot/0_2.png';
import rome2 from '../assets/Rome/screenshot/1.png';
import rome3 from '../assets/Rome/screenshot/2.png';
import rome4 from '../assets/Rome/screenshot/3.png';
// ///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////SHERIFF COLT////////////////////
import sheriffBanner from '../assets/Sheriff Colt_/banner.png';
import sheriffBannerMob from '../assets/Sheriff Colt_/banner_mobil.png';
import sheriff_preview from '../assets/Sheriff Colt_/preview.png';
import sheriff_icon from '../assets/Sheriff Colt_/icon.png';
import sheriff_promo from '../assets/Sheriff Colt_/promo.png';
import sheriff1 from '../assets/Sheriff Colt_/screenshot/S1.png';
import sheriff2 from '../assets/Sheriff Colt_/screenshot/S2.png';
import sheriff3 from '../assets/Sheriff Colt_/screenshot/S3.png';
import sheriff4 from '../assets/Sheriff Colt_/screenshot/S4.png';
import sheriff5 from '../assets/Sheriff Colt_/screenshot/S5.png';
// ///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////D´Cirque////////////////////
import cirqueBanner from '../assets/D`Cirque/banner.png';
import cirqueBannerMob from '../assets/D`Cirque/banner_mobil.png';
import cirque_preview from '../assets/D`Cirque/preview.png';
import cirque_icon from '../assets/D`Cirque/icon.png';
import cirque_promo from '../assets/D`Cirque/promo.png';

import cirque1 from '../assets/D`Cirque/Screenshots_/Screenshot1.png';
import cirque2 from '../assets/D`Cirque/Screenshots_/Screenshot2.png';
import cirque3 from '../assets/D`Cirque/Screenshots_/Screenshot3.png';
import cirque4 from '../assets/D`Cirque/Screenshots_/Screenshot4.png';
import cirque5 from '../assets/D`Cirque/Screenshots_/Screenshot5.png';
import cirque6 from '../assets/D`Cirque/Screenshots_/Screenshot6.png';
import cirque7 from '../assets/D`Cirque/Screenshots_/Screenshot7.png';
import cirque8 from '../assets/D`Cirque/Screenshots_/Screenshot8.png';
import cirque9 from '../assets/D`Cirque/Screenshots_/Screenshot9.png';
import cirque10 from '../assets/D`Cirque/Screenshots_/Screenshot10.png';
// ///////////////////////////////////////////////////////////////////////////////




import iOsIcon from '../assets/Apple-icon.png';
import ANDROIDIcon from '../assets/Android-icon.png';
import MACIcon from '../assets/MacOX-icon.png';
import WINDOWSIcon from '../assets/Windows-icon.png';
import HTML5Icon from '../assets/HTML5-icon.png';





export const get = (state) => {

    switch(state) {
        case 'games':
            return gamesObj();
        case 'about':
            return aboutObj();
        case 'about/page':
            return aboutPageObj();
        case 'news':
            return newsPageObj();
        case 'contact':
            return contacsPageObj();
        case 'footer':
            return footerPageObj();
        case 'privacy':
            return privacyPage();
        default: 
        return
    }
}

export const post = (state, index) => {
    switch (state) {
        case 'news':
            const news = newsPageObj();
            return news[index];
        case '/games':
            const game = gamesObj();
            return game[index];
        default:
    }
    
}

const privacyPage = () => {
    const obj = [
        {
            title: 'PRIVACY POLICY',
            title1: 'PETER & SONS RESPECTS YOUR PRIVACY',
            description1: `It is our policy to always secure your privacy when you visit peterandsons.org.
             Please read our policy statement to understand how we collect your information and how it will be used.`,
            title2: 'INFORMATION COLLECTION',
            description2: `The information related to your use of the Site is automatically tracked while you are on the Site.`,
            description2_title: `Some examples of this information are as below:`,
            description2_part1: `URL tracking information`,
            description2_part2: `User's browser`,
            description2_part3: `User's IP address`,
            description2_part4: `Files downloaded or viewed by the user`,
            description2_part5: `User's path through the Web site`,
            description2_part6: `Date, time and frequency of accessing site`,
            description2_part7: `Duration of time spent reviewing certain features`,

            description3: `We may also use cookies to track your usage on the Site. 
            You have the option to disable cookies via your Web browser settings.`,
            description3_title: `For your reference, cookies:`,
            description3_part1: `Are a small data file written to a user's hard drive`,
            description3_part2: `Allows automated log-in`,
            description3_part3: `May contain information such as a login ID or other information about preferences or Internet use`,

            description4: `Our website employs the use the various third-party services. Through the use of our website,
            these services may place anonymous cookies on the Visitor’s browser and may send their own cookies to the Visitor’s cookie file.`,
            description4_title: `We use the following third-party services:`,
            description4_part1: `Google Analytics and Google Analytics tracking codes`,
            description4_part2: `Google AdWords and Google AdWords Conversion Tag`,
            description4_part3: `Facebook Pixel Code`,
            description4_part4: `LinkedIn Insight Tag`,
            description4_part5: `Twitter Universal Website Tag Code`,

            title3: `PERSONAL INFORMATION`,
            description5_title: `The below information may be collected when you contact or inquire from us:`,
            description5_part1: `Name`,
            description5_part2: `Address`,
            description5_part3: `Telephone number(s)`,
            description5_part4: `E-mail address`,

            title4: `HOW PETER & SONS USES INFORMATION COLLECTED`,
            description6_title: `Peter & Sons uses the information we collect on the Site to:`,
            description6_part1: `Improve our ability to support your requests and inquiries`,
            description6_part2: `Contact you in relation to our products or services, industry events where you can meet us.`,
            description6_part3: `Carry out certain profiling of you and your activity`,
            description6_part4: `Improve our marketing and promotional efforts`,
            description6_part5: `Statistically analyze usage of our Site`,
            description6_part6: `Improve our content on the Site`,
            description6_part7: `Customize the content and layout of the Site`,
            description6: `In regards to the information we collect when you email us, we may use your email 
            address to contact and/or reply to your comments and concerns. Your communication and our replies
             may be stored in case of a future correspondence. Your email address will not be shared with anyone
              outside the company.`,
            
            title5: `THIRD-PARTY DISCLOSURE`,
            description7: `In regards to the information we collect when you email us, we may use your email 
            address to contact and/or reply to your comments and concerns. Your communication and our replies
             may be stored in case of a future correspondence. Your email address will not be shared with anyone
              outside the company.`,
            description7_title: `At our discretion, Peter & Sons will cooperate with law enforcement
             agencies and disclose information when the below situation occurs:`,
            description7_part1: `To identify those who use our site for illegal activities`,
            description7_part2: `When required by the law`,
            description7_part3: `Protect ourselves from legal liability`,
            description7_part4: `Protect someone's safety or the integrity of the site`,

            title6: `SECURITY`,
            description8: `Reasonable measures of security have been implemented by Peter &
             Sons to ensure the confidentiality of information is protected. Only authorized
              personnel are allowed access to such information.`,

            title7: `THIRD-PARTY SITES`,
            description9: `Peter & Sons is only responsible to control the use and disclosure of 
            information we collect on this site. The privacy of other sites or third parties to
             whom you may link from our site is not covered in this policy.`,
             title8: `GENERAL`,
            description10: `Peter & Sons reserves the right to change this Privacy Policy
             based on Peter & Sons. Terms and Conditions of Use. Changes in the policy will
              be posted to this page as soon as reasonably possible and its changes will become
               effective immediately. Therefore, it is advisable to periodically check this page 
               for any changes. By using the Site, you have automatically given consent for Peter 
               & Sons to use your information in accordance with the Privacy Policy then in effect.`
        }
    ]
    return obj;
}


const footerPageObj = () => {
    const obj = [
        {
            bg_title: `P&S LEADING ENTERTAINMENT`,
            title: 'CONTACT',
            description: `Send us your inquiries by filling the contact form and we will get in touch with you as soon as possible.`,
            email1: 'Info@peterandsons.org',
            email2: 'jobs@peterandsons.org',
            location: `Peter and Sons LLC 
            Armenia, Yerevan, Yekmalyan str 6., 0002`,

        }
    ]
    return obj;
}

const contacsPageObj = () => {
    const obj = [

            {
                
                title: 'WORK WITH US',
                description1: 'We work hard to create amazing, innovative gaming experiences for players everywhere.',
                description2: `We are bringing together a team of creative, brilliant, 
                and passionate people to build a new generation of state of the art slot games.`,
                desc_question: `Would you want to join us?`,
                button: `GET IN TOUCH`,
                logo: ps_logo_white
        
            }
        ]
    return obj;
}


const newsPageObj = () => {
    const obj = [
        {
            newsTitle: `WE CREATE UNIQUE PLAYER EXPERIENCES!`,
            newsDescription1: `With the player in mind, we strive to develop new and immersive gaming experiences. `,
            newsDescription2: `Games that catch the eye, exceed expectations and break new grounds when it comes to gameplay, mechanics, and visuals.`,
            id: 0,
            partnersLogo1: quick_fire,
            partnersLogo2: solid_gaming,
            partnersLogo3: qTech_logo,
            partnersLogo4: microgaming_logo,
            partnersLogo5: leander_logo,
            title: 'Filthy Pirate',
            newsPage_title: `PETER & SONS REDIES THE HEAVY ARTILLERY WITH FILTHY PIRATES`,
            description: 'Call of Kora is a 5x3 video slot with stacked symbols. Include avalanche cascades and a high volatility picker bonus game, the...',
            gameIcon: pirates_preview,
            url: "https://s3-eu-west-1.amazonaws.com/games-shared.public/logos/filthy-pirates.png",
            // gameIcon: skull,
            gameUrl: "https://www.casumo.com/sv/spela/filthy-pirates/",

            desc1_title: `YEREVAN, ARMENIA - LEADING DEVELOPER ANNOUNCED TODAY THE IMMEDIATE RELEASE OF FILTHY PIRATES ON CASUMO ONLINE CASINO. `,
            desc1_text1: `Filthy Pirates, a video slot with 6 reels, 4 rows, 
            and 4,096 All Ways to win in the base game. We introduce Pirate Black Eye,
             who is chasing the Gold Captain Wilson is carrying back from his last raid at Tortuga Island.`,
            desc1_text2: `A pirate ship and a merchant vessel battle it out among Random Wilds and a bonus Free Spins
             game with reel expansion where you get up to 46,656 possible ways to win.`,
            desc1_text3: `The Chase is a Free Spin phase that uses a 6x6 board,
             All ways with random wilds and retriggering. Sink the captainship to trigger more free spins.
            The game is fully responsive and adapts between portrait and landscape. 
            `,
            otherDescription: "",
        },
        {
            title: 'Filthy Pirate',
            newsPage_title: `PETER & SONS REDIES THE HEAVY ARTILLERY WITH FILTHY PIRATES`,
            description: 'Call of Kora is a 5x3 video slot with stacked symbols. Include avalanche cascades and a high volatility picker bonus game, the...',
            gameIcon: pirates_preview,
            url: "https://s3-eu-west-1.amazonaws.com/games-shared.public/logos/filthy-pirates.png",
            // gameIcon: skull,
            gameUrl: "https://www.casumo.com/sv/spela/filthy-pirates/",

            desc1_title: `YEREVAN, ARMENIA - LEADING DEVELOPER ANNOUNCED TODAY THE IMMEDIATE RELEASE OF FILTHY PIRATES ON CASUMO ONLINE CASINO. `,
            desc1_text1: `Filthy Pirates, a video slot with 6 reels, 4 rows, 
            and 4,096 All Ways to win in the base game. We introduce Pirate Black Eye,
             who is chasing the Gold Captain Wilson is carrying back from his last raid at Tortuga Island.`,
            desc1_text2: `A pirate ship and a merchant vessel battle it out among Random Wilds and a bonus Free Spins
             game with reel expansion where you get up to 46,656 possible ways to win.`,
            desc1_text3: `The Chase is a Free Spin phase that uses a 6x6 board,
             All ways with random wilds and retriggering. Sink the captainship to trigger more free spins.
            The game is fully responsive and adapts between portrait and landscape. 
            `,
            otherDescription: "",
            partner_title: `ABOUT CASUMO`,
            partner_desc1: `Casumo is an open, honest and friendly online casino where you 
            can have fun playing slot machines, jackpots, table games, live casino or betting
             on your favorite sport. We believe in the joy of play, a feeling we want to share
              with all our players, wherever they are. That is why we’ve been focusing 
            all our efforts since 2012 on offering you an exceptional and fun experience through play.`,
            partner_desc2: `Contact us`,
            partner_email: `hey@casumo.com`,
            partner_location: `Casumo, The Unicorn Centre, Triq l-Uqija, Swieqi, SWQ 2335, Malta`,
            company_title: `ABOUT PETER & SONS`,
            company_desc: `P&S is a fresh-new game development studio formed by an international group of
                professionals and game enthusiasts with broad experience in mobile game and casino game development.
                We work with world-class artists, mathematicians, and musicians striving to produce some of the 
                most innovative, creative, and entertaining slots on the market. With our proprietary framework
                for game development, we can efficiently deploy fully responsive cross-platform games in html5
                and as native apps (Android, iOS), covering every regulatory requirement. We provide
                quick cost-efficient outsourcing services to publishers, operators, and game developers,
                embracing the full lifecycle of a project, from idea to release.`,

            id: 1
        },
        {
            title: 'Filthy Pirate',
            newsPage_title: `PETER & SONS REDIES THE HEAVY ARTILLERY WITH FILTHY PIRATES`,
            description: 'Call of Kora is a 5x3 video slot with stacked symbols. Include avalanche cascades and a high volatility picker bonus game, the...',
            gameIcon: pirates_preview,
            url: "https://s3-eu-west-1.amazonaws.com/games-shared.public/logos/filthy-pirates.png",
            // gameIcon: skull,
            gameUrl: "https://www.casumo.com/sv/spela/filthy-pirates/",

            desc1_title: `YEREVAN, ARMENIA - LEADING DEVELOPER ANNOUNCED TODAY THE IMMEDIATE RELEASE OF FILTHY PIRATES ON CASUMO ONLINE CASINO. `,
            desc1_text1: `Filthy Pirates, a video slot with 6 reels, 4 rows, 
            and 4,096 All Ways to win in the base game. We introduce Pirate Black Eye,
             who is chasing the Gold Captain Wilson is carrying back from his last raid at Tortuga Island.`,
            desc1_text2: `A pirate ship and a merchant vessel battle it out among Random Wilds and a bonus Free Spins
             game with reel expansion where you get up to 46,656 possible ways to win.`,
            desc1_text3: `The Chase is a Free Spin phase that uses a 6x6 board,
             All ways with random wilds and retriggering. Sink the captainship to trigger more free spins.
            The game is fully responsive and adapts between portrait and landscape. 
            `,
            otherDescription: "",tion: "If your eye is keen and your aim is sharp, then you should drop by Abe’s Saloon, a 5 reels x 3 rows video slot with 20 paylines. Features include Avalanche Cascades where winning symbols are replaced with new ones to form new winning combinations and a Bottle Shooting feature, a high volatility picker bonus feature triggered when 3 bottles (scatter symbols) appear on the screen. You can also buy the Bottle Shooting feature at any time during the game. RTP 95.00%",
            id: 2
        },
        {
            title: 'Filthy Pirate',
            newsPage_title: `PETER & SONS REDIES THE HEAVY ARTILLERY WITH FILTHY PIRATES`,
            description: 'Call of Kora is a 5x3 video slot with stacked symbols. Include avalanche cascades and a high volatility picker bonus game, the...',
            gameIcon: pirates_preview,
            url: "https://s3-eu-west-1.amazonaws.com/games-shared.public/logos/filthy-pirates.png",
            // gameIcon: skull,
            gameUrl: "https://www.casumo.com/sv/spela/filthy-pirates/",

            desc1_title: `YEREVAN, ARMENIA - LEADING DEVELOPER ANNOUNCED TODAY THE IMMEDIATE RELEASE OF FILTHY PIRATES ON CASUMO ONLINE CASINO. `,
            desc1_text1: `Filthy Pirates, a video slot with 6 reels, 4 rows, 
            and 4,096 All Ways to win in the base game. We introduce Pirate Black Eye,
             who is chasing the Gold Captain Wilson is carrying back from his last raid at Tortuga Island.`,
            desc1_text2: `A pirate ship and a merchant vessel battle it out among Random Wilds and a bonus Free Spins
             game with reel expansion where you get up to 46,656 possible ways to win.`,
            desc1_text3: `The Chase is a Free Spin phase that uses a 6x6 board,
             All ways with random wilds and retriggering. Sink the captainship to trigger more free spins.
            The game is fully responsive and adapts between portrait and landscape. 
            `,
            otherDescription: "",
            id: 3
        },
    ]

    return obj;
}


const aboutPageObj = () => {
   const obj = [
        {
            title: 'About',
            partnerLogo1: mighty_finger_logo,
            partnerLogo2: casumo_logo,
            partnerDdescription1: `4 exclusive titles developed for`,
            partnerDdescription1part: `AVAILABLE AT CASUMO`,
            partnerDdescription2: `We are proud to be part of the play, providing top-notch entertainment to a leading operator.`,
            description1: `GAMING IS AT THE HEART OF WHAT WE DO`,
            description2: `We are a passionate team with a fresh-new vision for slots.`,
            description3: `We strive to create superior & unique gaming experiences for players around the world.`,
            aboutPng: aboutPng,
            inspirationalPng: aboutInspiration,
            inspirationalTitle: 'INSPIRATIONAL',
            inspirationalDescription: 'Our games comply with the industries best practices on responsible gaming covering every regulatory requirement. Adapted to a broad set of audiences and cultures, and localized to multiple languages to be deployed in numerous territories.',
            creativePng: aboutCreative,
            creativeTitle: 'CREATIVE',
            creativeDescription: 'We work with world-class artist, musicians, and mathematicians, featuring their work  to create innovative themes, designs, and features, providing new engaging, and exciting experiences for the players.',
            professionalPng: aboutProfessional,
            professionalTitle: 'PROFESSIONAL',
            professionalDescription: 'We are fast and cost-efficient. Our proprietary framework for agile game development enables us to deploy effectively completely responsive cross-plattform multi-language games in html5, and as native apps covering any regulatory requirement.',
            id: 0
        }
    ]

    return obj;
}

 const aboutObj = () => {
     const obj = [
         {
             title: 'ABOUT',
             image: ps_logo_white,
             
             description1: `P&S is a fresh-new game development studio formed by an international group 
             of professionals and game enthusiasts with broad experience in mobile game and casino game
              development.`,
             description2: `We work with world-class artists, mathematicians, and musicians striving to 
              produce some of the most innovative, creative, and entertaining slots on the market. With
               our proprietary framework for game development, we can efficiently deploy fully responsive
                cross-platform games in html5 and as native apps (Android, iOS), covering every regulatory
                 requirement.`,
             description3: ` We provide quick cost-efficient outsourcing services to publishers, operators,
             and game developers, embracing the full lifecycle of a project, from idea to release.`,
            id: 1
         }
         
     ]

     return obj;
 }


const gamesObj = (index) => {
    const obj = [
        {
            name: 'call of kora',
            banner: koraBanner,
            bannerMob: koraBannerMob,

            popularity: false,
            featured: true,
            setting: false,
            title: 'Call of Kora',
            gameTitle: 'CALL OF KORA',

            description: 'Call of Kora is a 5x3 video slot with stacked symbols. Include avalanche cascades and a high volatility picker bonus game, the...',
            url: kora_preview,
            gameUrl: "https://www.casumo.com/sv/spela/call-of-kora/",
            gameIcon: kora_icon,
            gamePreview: kora_preview,
            gamePromoIcon: kora_promo,


            gameDescription: `A 5 reel x 3 rows video slot game with 10 pay lines that pay both ways.
             It comes with stacked symbols, expanding sticky wilds, and a re-spin feature. The Cora Stone
              is the Wild symbol and can only appear on the 3 middle reels when this happens, the Wilds
               expand and become sticky to give you up to 3 re-spins.
            The game also has a Wild symbol that substitutes all symbols except the scatter one. 
            Peter & Sons collaborated with Mighty Finger in the production of this game.
            
            `,
            otherDescription: "",
            gameScreens : [kora1, kora2, kora3, kora4, kora5, kora6],
            descriptionTitle: `Heed the Call of Kora!`,

            gameFeatures: `GAME FEATURES`,
            gameType: `GAME TYPE `,
            sesionVolatility: `Session volatility`,
            rtp: `RTP`,
            maxWin: `MAX WIN`,
            fullSize: `FULL SIZE`,
            initialDownload: `INITIAL DOWNLOAD`,
            productSource: `PRODUCT SOURCE`,
            language:`Language`,
            compatibleWith: `COMPATIBLE WITH`,
            
            gameTypeValue: `VIDEO SLOTS - 3D GAMES `,
            sesionVolatilityValue: `MEDIUM`,
            rtpValue: `95.14%`,
            maxWinValue: `2200X`,
            fullSizeValue: `12.4MB`,
            initialDownloadValue: `12.4MB`,
            productSourceValue: `DEVELOPED`,
            languageValue:`MULTI - LANGUAGE`,
            iOs: `IOS`,
            iOsIcon: iOsIcon,
            ANDROID: `ANDROID`,
            ANDROIDIcon: ANDROIDIcon,
            WINDOWS: `WINDOWS`,
            WINDOWSIcon: WINDOWSIcon,
            MAC: `MAC OS`,
            MACIcon: MACIcon,
            HTML5: `HTML5`,
            HTML5Icon: HTML5Icon,
            gameBgIcon: skull,

            partnerDescription1: `FILTHY PIRATES - DEVELOPED BY P&S FOR `,
            partnerLogo1: mighty_finger_logo,
            partnerDescription2: `PLAY FILTHY PIRATES ONLINE AT `,
            partnerLogo2: casumo_logo,

            id: 0
        },
        {
            name: 'filthy pirate',
            banner: pirateBanner,
            bannerMob: pirateBannerMob,

            popularity: false,
            featured: true,
            setting: false,

            title: 'Filthy Pirates',
            gameTitle: 'FILTHY PIRATES',
            description: 'Call of Kora is a 5x3 video slot with stacked symbols. Include avalanche cascades and a high volatility picker bonus game, the...',
            url: pirates_preview,
            gameIcon: pirates_icon,
            gamePreview: pirates_preview,
            gamePromoIcon: pirates_promo,
            gameUrl: "https://www.casumo.com/sv/spela/filthy-pirates/",
            gameDescription: `Filthy Pirates, a video slot with 6 reels, 4 rows, and 4,096 All Ways to win in the base game. We introduce Pirate Black Eye, who is chasing the Gold Captain Wilson is carrying back from his last raid at Tortuga Island.
            A pirate ship and a merchant vessel battle it out among Random Wilds and a bonus Free Spins game with reel expansion where you get up to 46,656 possible ways to win.
            The Chase is a Free Spin phase that uses a 6x6 board, All ways with random wilds and retriggering. Sink the captainship to trigger more free spins.
            The game is fully responsive and adapts between portrait and landscape.
            `,
            gameScreens : [pirate1, pirate2, pirate3, pirate4, pirate5],
            descriptionTitle: `Take what you can give nothing back!`,
            otherDescription: "",

            gameFeatures: `GAME FEATURES`,
            gameType: `Game type`,
            sesionVolatility: `Session volatility`,
            rtp: `RTP`,
            maxWin: `Max Win`,
            fullSize: `Full Size`,
            initialDownload: `INITIAL DOWNLOAD`,
            productSource: `Product Source`,
            language:`Language`,
            compatibleWith: `Compatible with`,
            
            gameTypeValue: `VIDEO SLOTS - 3D GAMES `,
            sesionVolatilityValue: `MEDIUM`,
            rtpValue: `97.38%`,
            maxWinValue: `15000X`,
            fullSizeValue: `12.4MB`,
            initialDownloadValue: `12.4MB`,
            productSourceValue: `DEVELOPED`,
            languageValue:`MULTI - LANGUAGE`,
            iOs: `IOS`,
            iOsIcon: iOsIcon,
            ANDROID: `ANDROID`,
            ANDROIDIcon: ANDROIDIcon,
            WINDOWS: `WINDOWS`,
            WINDOWSIcon: WINDOWSIcon,
            MAC: `MAC OS`,
            MACIcon: MACIcon,
            HTML5: `HTML5`,
            HTML5Icon: HTML5Icon,

            partnerDescription1: `FILTHY PIRATES - DEVELOPED BY P&S FOR `,
            partnerLogo1: mighty_finger_logo,
            partnerDescription2: `PLAY FILTHY PIRATES ONLINE AT `,
            partnerLogo2: casumo_logo,


            id: 1
        },
        {
            name: `saloon`,
            banner: saloonBanner,
            bannerMob: saloonBannerMob,

            title: 'Saloon',
            gameTitle: `ABE'S SALOON`,

            popularity: false,
            featured: true,
            setting: false,

            description: 'Abe’s Saloon is a  5x3 video slot with stacked symbols. Include avalanche cascades and a high volatility picker bonus game, the...',
            url: saloon_preview,
            gameUrl: 'https://www.casumo.com/sv/spela/abes-saloon/',
            gameIcon: saloon_icon,
            gamePreview: saloon_preview,
            gamePromoIcon: saloon_promo,

            gameDescription: `A 5 reels x 3 rows video slot with 20 pay lines.
             Features include Avalanche Cascades and a Bottle Shooting feature,
              a high volatility picker bonus feature triggered when 3 bottles appear on the screen.
               You can also buy the Bottle Shooting feature at any time during the game.
            Peter & Sons collaborated with Mighty Finger in the production of this game.
            
            `,
            otherDescription: "If your eye is keen and your aim is sharp, then you should drop by Abe’s Saloon, a 5 reels x 3 rows video slot with 20 paylines. Features include Avalanche Cascades where winning symbols are replaced with new ones to form new winning combinations and a Bottle Shooting feature, a high volatility picker bonus feature triggered when 3 bottles (scatter symbols) appear on the screen. You can also buy the Bottle Shooting feature at any time during the game. RTP 95.00%",
            gameScreens : [saloon1, saloon2, saloon3, saloon4, saloon5,saloon6,saloon7],
            descriptionTitle: `If your eye is keen and your aim is sharp, drop by Abe’s Saloon!`,


            gameFeatures: `GAME FEATURES`,
            gameType: `GAME TYPE `,
            sesionVolatility: `Session volatility`,
            rtp: `RTP`,
            maxWin: `MAX WIN`,
            fullSize: `FULL SIZE`,
            initialDownload: `INITIAL DOWNLOAD`,
            productSource: `PRODUCT SOURCE`,
            language:`Language`,
            compatibleWith: `COMPATIBLE WITH`,
            
            gameTypeValue: `VIDEO SLOTS - 3D GAMES `,
            sesionVolatilityValue: `MEDIUM`,
            rtpValue: `95.10%`,
            maxWinValue: `3000X`,
            fullSizeValue: `12.4MB`,
            initialDownloadValue: `12.4MB`,
            productSourceValue: `DEVELOPED`,
            languageValue:`MULTI - LANGUAGE`,
            iOs: `IOS`,
            iOsIcon: iOsIcon,
            ANDROID: `ANDROID`,
            ANDROIDIcon: ANDROIDIcon,
            WINDOWS: `WINDOWS`,
            WINDOWSIcon: WINDOWSIcon,
            MAC: `MAC OS`,
            MACIcon: MACIcon,
            HTML5: `HTML5`,
            HTML5Icon: HTML5Icon,
            gameBgIcon: skull,

            partnerDescription1: `FILTHY PIRATES - DEVELOPED BY P&S FOR `,
            partnerLogo1: mighty_finger_logo,
            partnerDescription2: `PLAY FILTHY PIRATES ONLINE AT `,
            partnerLogo2: casumo_logo,

            id: 2
        },
        {
            name: 'the book of seth',
            banner: bookBanner,
            bannerMob: bookBannerMob,


            title: 'The Book Of Seth',
            gameTitle: 'THE BOOK OF SETH',

            popularity: false,
            featured: true,
            setting: false,

            description: 'Call of Kora is a 5x3 video slot with stacked symbols. Include avalanche cascades and a high volatility picker bonus game, the...',
            url: book_preview,
            gameUrl: "https://www.casumo.com/sv/spela/book-of-seth/",
            gameIcon: book_icon,
            gamePreview: book_preview,
            gamePromoIcon: book_promo,

            gameDescription: `Peter & Sons worked together with Mighty Finger
             producing the Art of this gripping Egyptian-themed video slot, Book of Seth. 
            Once more, Dr. Prudence Bob embarks on an epic journey to uncover
             the secrets of the great pyramid. Laying in ruins, hidden from the
              eyes of the world, once built to reflect the greatness of Seth, the god of chaos. 
            Book of Seth is a high volatility adventure video slot, with 5 reels,
             3 rows, and 10 fixed Paylines. Three, four, or five Scatters give you 10,
              15, or 30 Free Spins, which can be retriggered. Every time you get 3 or
               more matching symbols on a pay line, wins are generated. The Book symbol 
               is both the scatter and the wild and substitutes any other symbol to complete a winning combination. 
            
            `,
            otherDescription: "",
            gameScreens : [book1, book2, book3, book4, book5],
            descriptionTitle: `Discover the secrets of the great pyramid!
            `,

            gameFeatures: `GAME FEATURES`,
            gameType: `GAME TYPE `,
            sesionVolatility: `Session volatility`,
            rtp: `RTP`,
            maxWin: `MAX WIN`,
            fullSize: `FULL SIZE`,
            initialDownload: `INITIAL DOWNLOAD`,
            productSource: `PRODUCT SOURCE`,
            language:`Language`,
            compatibleWith: `COMPATIBLE WITH`,
            
            gameTypeValue: `VIDEO SLOTS - 3D GAMES `,
            sesionVolatilityValue: `MEDIUM`,
            rtpValue: `96%`,
            maxWinValue: `30000X`,
            fullSizeValue: `12.4MB`,
            initialDownloadValue: `12.4MB`,
            productSourceValue: `DEVELOPED`,
            languageValue:`MULTI - LANGUAGE`,
            iOs: `IOS`,
            iOsIcon: iOsIcon,
            ANDROID: `ANDROID`,
            ANDROIDIcon: ANDROIDIcon,
            WINDOWS: `WINDOWS`,
            WINDOWSIcon: WINDOWSIcon,
            MAC: `MAC OS`,
            MACIcon: MACIcon,
            HTML5: `HTML5`,
            HTML5Icon: HTML5Icon,
            gameBgIcon: skull,

            partnerDescription1: `FILTHY PIRATES - DEVELOPED BY P&S FOR `,
            partnerLogo1: mighty_finger_logo,
            partnerDescription2: `PLAY FILTHY PIRATES ONLINE AT `,
            partnerLogo2: casumo_logo,

            id: 3
        },
        {
            name: 'sheriff colt',
            banner: sheriffBanner,
            bannerMob: sheriffBannerMob,


            title: 'Sheriff Colt',
            gameTitle: 'SHERIFF COLT',

            popularity: true,
            featured: false,
            setting: true,

            description: 'Abe’s Saloon is a  5x3 video slot with stacked symbols. Include avalanche cascades and a high volatility picker bonus game, the...',
            url: sheriff_preview,
            gameUrl: "",
            gameIcon: sheriff_icon,
            gamePreview: sheriff_preview,
            gamePromoIcon: sheriff_promo,

            gameDescription: `Sheriff Colt, a new game with a simple and
             engaging story, unique style, proven mechanics, and eye-catching 
             visuals that will appeal to a broad set of casino players. 
            In Sheriff Colt, we introduce you to this legendary character, the
             law in the southern town of White Cuervo, where bandits prowl like wolfs looking for prey.
            The game has a 5x3 panel in the Main game with ten pay lines, wilds and scatters.
            A free spins game is triggered when you get three or more scatters.
             In Free spins, three or more scatters retrigger extra free spins.
              Wilds are sticky in this mode and will stay for the entire round.
            `,
            otherDescription: "If your eye is keen and your aim is sharp, then you should drop by Abe’s Saloon, a 5 reels x 3 rows video slot with 20 paylines. Features include Avalanche Cascades where winning symbols are replaced with new ones to form new winning combinations and a Bottle Shooting feature, a high volatility picker bonus feature triggered when 3 bottles (scatter symbols) appear on the screen. You can also buy the Bottle Shooting feature at any time during the game. RTP 95.00%",
            gameScreens : [sheriff1, sheriff2, sheriff3, sheriff4, sheriff5],
            descriptionTitle: `Stay sharp, don’t trust anyone!`,

            gameFeatures: `GAME FEATURES`,
            gameType: `GAME TYPE `,
            sesionVolatility: `Session volatility`,
            rtp: `RTP`,
            maxWin: `MAX WIN`,
            fullSize: `FULL SIZE`,
            initialDownload: `INITIAL DOWNLOAD`,
            productSource: `PRODUCT SOURCE`,
            language:`Language`,
            compatibleWith: `COMPATIBLE WITH`,
            
            gameTypeValue: `VIDEO SLOTS - 3D GAMES `,
            sesionVolatilityValue: `MEDIUM`,
            rtpValue: `95,08%`,
            maxWinValue: `12000X`,
            fullSizeValue: `12.4MB`,
            initialDownloadValue: `12.4MB`,
            productSourceValue: `IN DEVELOPMENT`,
            languageValue:`MULTI - LANGUAGE`,
            iOs: `IOS`,
            iOsIcon: iOsIcon,
            ANDROID: `ANDROID`,
            ANDROIDIcon: ANDROIDIcon,
            WINDOWS: `WINDOWS`,
            WINDOWSIcon: WINDOWSIcon,
            MAC: `MAC OS`,
            MACIcon: MACIcon,
            HTML5: `HTML5`,
            HTML5Icon: HTML5Icon,
            gameBgIcon: skull,

            partnerDescription1: `FILTHY PIRATES - DEVELOPED BY P&S FOR `,
            partnerLogo1: mighty_finger_logo,
            partnerDescription2: `PLAY FILTHY PIRATES ONLINE AT `,
            partnerLogo2: casumo_logo,

            id: 4
        },
        {
            name: `d'circus`,
            banner: cirqueBanner,
            bannerMob: cirqueBannerMob,

            title: "D´Cirque ",
            gameTitle: `D´Cirque `,

            popularity: true,
            featured: false,
            setting: true,

            url: cirque_preview,
            gameUrl: "/games/cirque",
            gameIcon: cirque_icon,
            gamePreview: cirque_preview,
            gamePromoIcon: cirque_promo,

            gameDescription: `D´cirque is a circus-themed game with beautiful visuals and fun,
             engaging mechanics targeting a broad set of casino players.
            A multiplatform, five reels, three rows, medium volatility video slot game 
            offering 25 fix pay lines, expanding wilds with respins and a bonus game with free spins. 
            During the main game expanding wilds may appear on reels 2, 3 and 4 turning a light
             on below the reel triggering a free spin. Getting additional wilds keeps the game
              going until turning on the three lamps, launching a bonus-game that gives the player 10 extra free spins. 
            During the bonus-game, at least one of the reels, if not the tree, becomes a wild
             reel offering a max win of x3000.             
            `,
            otherDescription: "",
            gameScreens : [cirque1, cirque2, cirque3, cirque4, cirque5, cirque6, cirque7, cirque8, cirque9, cirque10],
            descriptionTitle: `Immerse yourself in the magic of the circus!`,

            gameFeatures: `GAME FEATURES`,
            gameType: `GAME TYPE `,
            sesionVolatility: `Session volatility`,
            rtp: `RTP`,
            maxWin: `MAX WIN`,
            fullSize: `FULL SIZE`,
            initialDownload: `INITIAL DOWNLOAD`,
            productSource: `PRODUCT SOURCE`,
            language:`Language`,
            compatibleWith: `COMPATIBLE WITH`,
            
            gameTypeValue: `VIDEO SLOTS - 3D GAMES `,
            sesionVolatilityValue: `MEDIUM`,
            rtpValue: `96.4%`,
            maxWinValue: `3000X`,
            fullSizeValue: `11.8MB`,
            initialDownloadValue: `11.8MB`,
            productSourceValue: `IN DEVELOPMENT`,
            languageValue:`MULTI - LANGUAGE`,
            iOs: `IOS`,
            iOsIcon: iOsIcon,
            ANDROID: `ANDROID`,
            ANDROIDIcon: ANDROIDIcon,
            WINDOWS: `WINDOWS`,
            WINDOWSIcon: WINDOWSIcon,
            MAC: `MAC OS`,
            MACIcon: MACIcon,
            HTML5: `HTML5`,
            HTML5Icon: HTML5Icon,
            gameBgIcon: skull,

            partnerDescription1: `FILTHY PIRATES - DEVELOPED BY P&S FOR `,
            partnerLogo1: mighty_finger_logo,
            partnerDescription2: `PLAY FILTHY PIRATES ONLINE AT `,
            partnerLogo2: casumo_logo,

            id: 5
        },
        {
            name: `Punch Club`,
            banner: fightBanner,
            bannerMob: fightBannerMob,

            title: "Punch Club",
            gameTitle: `PUNCH CLUB`,

            popularity: true,
            featured: false,
            setting: true,

            url: fight_preview,
            gameUrl: "",
            gameIcon: fight_icon,
            gamePreview: fight_preview,
            gamePromoIcon: fight_promo,

            gameDescription: `Join the Punch Club! Where the most ferocious underground fights take place,
             chasing glory and the Big price! This is a 5 reel x 3 row video slot game with 25 fixed pay lines,
              random multipliers, Wilds, Scatters, and a free spin mode giving a max win of 3000x.
               A random multiplier (1 to 10) applies in each spin, on the main game and the free spin mode.
                Collecting 3 scatters in the main game launches the free spin mode giving 10 additional free spins.
                 In this mode, Scatters are collectible, providing 2 extra free spins, and high symbols become Wilds.
            `,
            otherDescription: "",
            gameScreens : [fight1, fight2, fight3, fight4, fight5],
            descriptionTitle: `Survive in the slums, hide your pride and punch!`,

            gameFeatures: `GAME FEATURES`,
            gameType: `GAME TYPE `,
            sesionVolatility: `Session volatility`,
            rtp: `RTP`,
            maxWin: `MAX WIN`,
            fullSize: `FULL SIZE`,
            initialDownload: `INITIAL DOWNLOAD`,
            productSource: `PRODUCT SOURCE`,
            language:`Language`,
            compatibleWith: `COMPATIBLE WITH`,
            
            gameTypeValue: `VIDEO SLOTS - 3D GAMES `,
            sesionVolatilityValue: `MEDIUM`,
            rtpValue: `97.38%`,
            maxWinValue: `3000X`,
            fullSizeValue: `12.4MB`,
            initialDownloadValue: `12.4MB`,
            productSourceValue: `IN DEVELOPMENT`,
            languageValue:`MULTI - LANGUAGE`,
            iOs: `IOS`,
            iOsIcon: iOsIcon,
            ANDROID: `ANDROID`,
            ANDROIDIcon: ANDROIDIcon,
            WINDOWS: `WINDOWS`,
            WINDOWSIcon: WINDOWSIcon,
            MAC: `MAC OS`,
            MACIcon: MACIcon,
            HTML5: `HTML5`,
            HTML5Icon: HTML5Icon,
            gameBgIcon: skull,

            partnerDescription1: `FILTHY PIRATES - DEVELOPED BY P&S FOR `,
            partnerLogo1: mighty_finger_logo,
            partnerDescription2: `PLAY FILTHY PIRATES ONLINE AT `,
            partnerLogo2: casumo_logo,

            id: 6
        },
        {
            name: `Johnan Legendarian`,
            banner: johnonBanner,
            bannerMob: johnonBannerMob,


            title: "Johnan Legendarian",
            gameTitle: `JOHNAN LEGENDARIAN`,

            popularity: true,
            featured: false,
            setting: true,

            url: johnon_preview,
            gameUrl: "",
            gameIcon: johnon_icon,
            gamePreview: johnon_preview,
            gamePromoIcon: johnon_promo,

            gameDescription: `Follow Johnan in his quest for lost cities, fighting his way
             through mythical creatures from the dawn of times. This is a 5-reel, 10-pay
              line mythical video slot. Wins are generated every time you get 3 or more
               matching symbols on a pay line on a single spin. The Magician symbol is
                the scatter, and Johnan’s statue, the wild, which substitutes for any
                 other symbol to complete a winning combination. 3, 4, or 5 Scatters
                  give you 10, 15, or 30 Free Spins, which can be re-triggered. RTP 96%
            `,
            otherDescription: "",
            gameScreens : [johnon1, johnon2, johnon1, johnon2, johnon1],
            descriptionTitle: `An epic adventure in search of riches and glory!`,

            gameFeatures: `GAME FEATURES`,
            gameType: `GAME TYPE `,
            sesionVolatility: `Session volatility`,
            rtp: `RTP`,
            maxWin: `MAX WIN`,
            fullSize: `FULL SIZE`,
            initialDownload: `INITIAL DOWNLOAD`,
            productSource: `PRODUCT SOURCE`,
            language:`Language`,
            compatibleWith: `COMPATIBLE WITH`,
            
            gameTypeValue: `VIDEO SLOTS - 3D GAMES `,
            sesionVolatilityValue: `MEDIUM`,
            rtpValue: `96%`,
            maxWinValue: `15000X`,
            fullSizeValue: `12.4MB`,
            initialDownloadValue: `12.4MB`,
            productSourceValue: `IN DEVELOPMENT`,
            languageValue:`MULTI - LANGUAGE`,
            iOs: `IOS`,
            iOsIcon: iOsIcon,
            ANDROID: `ANDROID`,
            ANDROIDIcon: ANDROIDIcon,
            WINDOWS: `WINDOWS`,
            WINDOWSIcon: WINDOWSIcon,
            MAC: `MAC OS`,
            MACIcon: MACIcon,
            HTML5: `HTML5`,
            HTML5Icon: HTML5Icon,
            gameBgIcon: skull,

            partnerDescription1: `FILTHY PIRATES - DEVELOPED BY P&S FOR `,
            partnerLogo1: mighty_finger_logo,
            partnerDescription2: `PLAY FILTHY PIRATES ONLINE AT `,
            partnerLogo2: casumo_logo,

            id: 7
        },{
            name: `Robin`,
            banner: robinBanner,
            bannerMob: robinBannerMob,


            title: "Robin",
            gameTitle: `ROBIN`,

            popularity: true,
            featured: false,
            setting: true,

            url: robin_preview,
            gameUrl: "",
            gameIcon: robin_icon,
            gamePreview: robin_preview,
            gamePromoIcon: robin_promo,

            gameDescription: `He keeps chasing the gold, greedy prince John is stockpiling
             at his castle. This is a 6x4 video slot with 4,096 pay lines that pay All Ways.
              It features Avalanche Cascades, Free Spins, increasing prize Multipliers,
               and expanding Wilds during Free Spins, which can be re-triggered. RTP 96%.
            `,
            otherDescription: "",
            gameScreens : [robin1, robin2, robin3],
            descriptionTitle: `Robin is back, stronger, and smarter!`,

            gameFeatures: `GAME FEATURES`,
            gameType: `GAME TYPE `,
            sesionVolatility: `Session volatility`,
            rtp: `RTP`,
            maxWin: `MAX WIN`,
            fullSize: `FULL SIZE`,
            initialDownload: `INITIAL DOWNLOAD`,
            productSource: `PRODUCT SOURCE`,
            language:`Language`,
            compatibleWith: `COMPATIBLE WITH`,
            
            gameTypeValue: `VIDEO SLOTS - 3D GAMES `,
            sesionVolatilityValue: `MEDIUM`,
            rtpValue: `96%`,
            maxWinValue: `15000X`,
            fullSizeValue: `12.4MB`,
            initialDownloadValue: `12.4MB`,
            productSourceValue: `IN DEVELOPMENT`,
            languageValue:`MULTI - LANGUAGE`,
            iOs: `IOS`,
            iOsIcon: iOsIcon,
            ANDROID: `ANDROID`,
            ANDROIDIcon: ANDROIDIcon,
            WINDOWS: `WINDOWS`,
            WINDOWSIcon: WINDOWSIcon,
            MAC: `MAC OS`,
            MACIcon: MACIcon,
            HTML5: `HTML5`,
            HTML5Icon: HTML5Icon,
            gameBgIcon: skull,

            partnerDescription1: `FILTHY PIRATES - DEVELOPED BY P&S FOR `,
            partnerLogo1: mighty_finger_logo,
            partnerDescription2: `PLAY FILTHY PIRATES ONLINE AT `,
            partnerLogo2: casumo_logo,

            id: 8
        },{
            name: `Rome`,
            banner: romeBanner,
            bannerMob: romeBannerMob,


            title: "Rome",
            gameTitle: `ROME`,

            popularity: true,
            featured: false,
            setting: true,

            url: rome_preview,
            gameUrl: "",
            gameIcon: rome_icon,
            gamePreview: rome_preview,
            gamePromoIcon: rome_promo,

            gameDescription: `Rome, 410 AD. The Roman empire is under attack by
             the Germanic tribes under the rule of King Alaric. Rome is under siege; take what you can!
            A game that comes with 4,096 All Ways to win in the base game.
             3 or more matching symbols on adjacent reels score a win. If the King
              appears on reel 1, he’ll bring with him a random number of Wilds.
               The same can happen if the Emperor appears on reel 6. If both the
                King and the Emperor appear on the same spin, a random number of
                 Wilds are shot into the middle reels, and 10 Free Spins are triggered.
            `,
            otherDescription: "",
            gameScreens : [rome1, rome2, rome3, rome4],
            descriptionTitle: `Treasures of Rome are awaiting you!`,

            gameFeatures: `GAME FEATURES`,
            gameType: `GAME TYPE `,
            sesionVolatility: `Session volatility`,
            rtp: `RTP`,
            maxWin: `MAX WIN`,
            fullSize: `FULL SIZE`,
            initialDownload: `INITIAL DOWNLOAD`,
            productSource: `PRODUCT SOURCE`,
            language:`Language`,
            compatibleWith: `COMPATIBLE WITH`,
            
            gameTypeValue: `VIDEO SLOTS - 3D GAMES `,
            sesionVolatilityValue: `MEDIUM`,
            rtpValue: `97.38%`,
            maxWinValue: `15000X`,
            fullSizeValue: `12.4MB`,
            initialDownloadValue: `12.4MB`,
            productSourceValue: `IN DEVELOPMENT`,
            languageValue:`MULTI - LANGUAGE`,
            iOs: `IOS`,
            iOsIcon: iOsIcon,
            ANDROID: `ANDROID`,
            ANDROIDIcon: ANDROIDIcon,
            WINDOWS: `WINDOWS`,
            WINDOWSIcon: WINDOWSIcon,
            MAC: `MAC OS`,
            MACIcon: MACIcon,
            HTML5: `HTML5`,
            HTML5Icon: HTML5Icon,
            gameBgIcon: skull,

            partnerDescription1: `FILTHY PIRATES - DEVELOPED BY P&S FOR `,
            partnerLogo1: mighty_finger_logo,
            partnerDescription2: `PLAY FILTHY PIRATES ONLINE AT `,
            partnerLogo2: casumo_logo,

            id: 9
        },
       

    ];



    return obj;
}

