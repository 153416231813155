import React from 'react';
import {connect} from 'react-redux';
import Slider from "react-slick";

import {fetchNewsSection} from '../../actions';
import NewsCard from './NewsCard';

import './allCss/GameView.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



class GamesSection extends React.Component  {

    state = {
        slidesToShow: 4
    }


    componentDidMount() {
        this.props.fetchNewsSection();
    }



    renderNewsSection = () => {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: false,
            responsive: [
                {
                    breakpoint: 4000,
                    settings: {
                      slidesToShow: 5,
                      slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                    }
                },
            ]
        }
        return(
            <div>

                <div id="new-section" >
                    <div className="news-title">{this.props.news[0].newsTitle}</div>
                    <div className="news-text">{this.props.news[0].newsDescription1}</div>
                    <div className="news-text">{this.props.news[0].newsDescription2}</div>
                </div>
                {/* <div>
                <Slider className="slider-partners-logo"  {...settings}>
                            <div>
                                <img className="partners-logo" alt="" src={this.props.news[0].partnersLogo1}/>
                            </div>
                            <div>
                                <img className="partners-logo" alt="" src={this.props.news[0].partnersLogo2}/>
                            </div>
                            <div>
                                <img className="partners-logo" alt="" src={this.props.news[0].partnersLogo3}/>
                            </div>
                            <div>
                                <img className="partners-logo" alt="" src={this.props.news[0].partnersLogo4}/>
                            </div>
                            <div>
                                <img className="partners-logo" alt="" src={this.props.news[0].partnersLogo5}/>
                            </div>
                    </Slider>
                </div> */}
            </div>
        );
        
    }

    render () {
        if(!this.props.news.length) {
            return <div>Loading...</div>

        }
        
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 4000,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      infinite: true,
                      dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 950,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 634,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    }
                },

            ]
          };

        return (
            <div >
                {this.renderNewsSection()}
                {/* <div id="news" >
                    <div id="h1-news-title">LATEST NEWS</div>
                    <div className="news-slider-section">

                        <Slider className="slider-section" {...settings}>
                            <div className="col-5">
                                <NewsCard news={this.props.news[1]} />
                            </div>
                            <div className="col-5">
                                <NewsCard news={this.props.news[1]} />
                            </div>
                            <div className="col-5">
                                <NewsCard news={this.props.news[3]} />
                            </div>

                        </Slider>
                    </div>
                </div> */}
                
            </div>
                
        )
    }
}

    const mapStateToProps = (state) =>{
        return {
            news: Object.values(state.news)
        }
    }


export default connect(mapStateToProps, {fetchNewsSection})(GamesSection);
