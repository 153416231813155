import {get, post} from '../apis/games';
import {
    FETCH_GAMES, FETCH_GAME, FETCH_ABOUT,
     FETCH_ABOUT_PAGE, FETCH_NEWS_SECTION,
      FETCH_CONTACT_SECTION, FETCH_FOOTER_SECTION, FETCH_NEWS,PRIVACY_POLICY_SECTION} from './Types';


export const fetchAboutPage = () => dispatch => {
    const response = get('about/page');

    dispatch( { type: FETCH_ABOUT_PAGE, payload: response} );
};

export const fetchFooterSection = () => dispatch => {
    const response = get('footer');

    dispatch({ type: FETCH_FOOTER_SECTION, payload: response });
    // history.push('/');
};
export const fetchPrivacyPolicySection = () => dispatch => {
    const response = get('privacy');

    dispatch({ type: PRIVACY_POLICY_SECTION, payload: response });
    // history.push('/');
};



export const fetchContactSection = () => dispatch => {
    const response = get('contact');

    dispatch( { type: FETCH_CONTACT_SECTION, payload: response} );
};

export const fetchNewsSection = () => dispatch => {
    const response = get('news');

    dispatch( { type: FETCH_NEWS_SECTION, payload: response} );
};

export const fetchAboutDetails = () => dispatch => {
    const response = get('about');

    dispatch( { type: FETCH_ABOUT, payload: response} );
};
export const fetchGames = () => dispatch => {
    const response = get('games');

    dispatch( { type: FETCH_GAMES, payload: response} );
};

export const fetchGame = id => dispatch => {
    const response = post('/games', id);

    dispatch({ type: FETCH_GAME, payload: response });
    // history.push('/');
};

export const fetchNews = id => dispatch => {
    const response = post('news', id);

    dispatch( { type: FETCH_NEWS, payload: response} );
};

