import { combineReducers } from 'redux';
// import { reducer as formReducer} from 'redux-form'; 

// import authReducer from './AuthReducer';
import gameReducer from './gameReducer';
import aboutReducer from './aboutReducer';
import aboutPageReducer from './aboutPageReducer';
import newsPageReducer from './newsPageReducer';
import contactPageReducer from './contactPageReducer';
import footerPageSection from './footerPageSection';
import privacyPageSection from './privacyPageSection';

export default combineReducers({
    games: gameReducer,
    abouts: aboutReducer,
    aboutPage: aboutPageReducer,
    news: newsPageReducer,
    contacts: contactPageReducer,
    footer: footerPageSection,
    privacy: privacyPageSection
});
