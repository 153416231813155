import React from 'react';
import {fetchPrivacyPolicySection} from '../actions';
import {connect} from 'react-redux';

import './HeaderItems/allCss/PrivacyPolicy.css';

class PrivacyPolicyPage extends React.Component {

    componentDidMount () {
        this.props.fetchPrivacyPolicySection();
    }

    render () {
        if (!this.props.privacy.length) {
            return <div>Loading..</div>
        }
        return (
            <div className="privacy-container">
                <div className="privacy-title">{this.props.privacy[0].title}</div>
                <div className="privacy-description-title">{this.props.privacy[0].title1}</div>
                <div className="privacy-description">{this.props.privacy[0].description1}</div>

                <div className="privacy-description-title">{this.props.privacy[0].title2}</div>
                <div className="privacy-description">{this.props.privacy[0].description2}</div>
                <div className="privacy-description">{this.props.privacy[0].description2_title}</div>
                <ul>
                    <li>{this.props.privacy[0].description2_part1}</li>
                    <li>{this.props.privacy[0].description2_part2}</li>
                    <li>{this.props.privacy[0].description2_part3}</li>
                    <li>{this.props.privacy[0].description2_part4}</li>
                    <li>{this.props.privacy[0].description2_part5}</li>
                    <li>{this.props.privacy[0].description2_part6}</li>
                    <li>{this.props.privacy[0].description2_part7}</li>
                </ul>
                <div className="privacy-description">{this.props.privacy[0].description3}</div>
                <div className="privacy-description">{this.props.privacy[0].description3_title}</div>
                <ul>
                    <li>{this.props.privacy[0].description3_part1}</li>
                    <li>{this.props.privacy[0].description3_part2}</li>
                    <li>{this.props.privacy[0].description3_part3}</li>
                </ul>
                <div className="privacy-description">{this.props.privacy[0].description4}</div>
                <div className="privacy-description">{this.props.privacy[0].description4_title}</div>
                <ul>
                    <li>{this.props.privacy[0].description4_part1}</li>
                    <li>{this.props.privacy[0].description4_part2}</li>
                    <li>{this.props.privacy[0].description4_part3}</li>
                    <li>{this.props.privacy[0].description4_part4}</li>
                    <li>{this.props.privacy[0].description4_part5}</li>
                </ul>
                <div className="privacy-description-title">{this.props.privacy[0].title3}</div>
                <div className="privacy-description">{this.props.privacy[0].description5}</div>
                <div className="privacy-description">{this.props.privacy[0].description5_title}</div>
                <ul>
                    <li>{this.props.privacy[0].description5_part1}</li>
                    <li>{this.props.privacy[0].description5_part2}</li>
                    <li>{this.props.privacy[0].description5_part3}</li>
                    <li>{this.props.privacy[0].description5_part4}</li>
                </ul>
                <div className="privacy-description-title">{this.props.privacy[0].title4}</div>
                <div className="privacy-description">{this.props.privacy[0].description6}</div>
                <div className="privacy-description">{this.props.privacy[0].description6_title}</div>
                <ul>
                    <li>{this.props.privacy[0].description6_part1}</li>
                    <li>{this.props.privacy[0].description6_part2}</li>
                    <li>{this.props.privacy[0].description6_part3}</li>
                    <li>{this.props.privacy[0].description6_part4}</li>
                    <li>{this.props.privacy[0].description6_part5}</li>
                    <li>{this.props.privacy[0].description6_part6}</li>
                    <li>{this.props.privacy[0].description6_part7}</li>
                </ul>
                <div className="privacy-description-title">{this.props.privacy[0].title5}</div>
                <div className="privacy-description">{this.props.privacy[0].description7}</div>
                <div className="privacy-description">{this.props.privacy[0].description7_title}</div>
                <ul>
                    <li>{this.props.privacy[0].description7_part1}</li>
                    <li>{this.props.privacy[0].description7_part2}</li>
                    <li>{this.props.privacy[0].description7_part3}</li>
                    <li>{this.props.privacy[0].description7_part4}</li>
                </ul>
                <div className="privacy-description-title">{this.props.privacy[0].title6}</div>
                <div className="privacy-description">{this.props.privacy[0].description8}</div>

                <div className="privacy-description-title">{this.props.privacy[0].title7}</div>
                <div className="privacy-description">{this.props.privacy[0].description9}</div>

                <div className="privacy-description-title">{this.props.privacy[0].title8}</div>
                <div className="privacy-description">{this.props.privacy[0].description10}</div>
                
                

            </div>
        )
    }
}

    const mapStateToProps = (state) => {
        return {
            privacy : Object.values(state.privacy)
        }
    }

export default connect(mapStateToProps, {fetchPrivacyPolicySection})(PrivacyPolicyPage);