import React from 'react';
import {connect} from 'react-redux';

import {fetchFooterSection} from '../actions';

import './App.css';
import { Link } from 'react-router-dom';
import PrivacyPolicyPage from './PrivacyPolicyPage';





class Footer extends React.Component {

    state = { firstName: '', LastName: '', email: '', phoneNumber: '', country: '', textMessage: '', checkbox1: false, checkbox2: false, openPrivacyPage: false };

    componentDidMount () {
        this.props.fetchFooterSection();

        window.addEventListener('resize', ()=> {
            if (this.state.openPrivacyPage) {

                if( window.innerWidth >= 1500) {
    
                    document.getElementById("privacy-link-sidebar").style.height = "560px";
                } else if (window.innerWidth >= 650) {
        
                    document.getElementById("privacy-link-sidebar").style.height = "520px";
                } else if (window.innerWidth >= 500){
        
                    document.getElementById("privacy-link-sidebar").style.height = "565px";
                }else if (window.innerWidth > 400) {
        
                    document.getElementById("privacy-link-sidebar").style.height = "500px";
                } else {
                    document.getElementById("privacy-link-sidebar").style.height = "525px";

                }
            }
        });

        window.addEventListener('scroll', () => {
            
            if ( window.scrollY < document.getElementById("contact-sidebar").offsetTop  - 876) {
                document.getElementById("privacy-link-sidebar").style.height = null;
            this.setState({openPrivacyPage: false});
            this.closeNav();
            }
        });

        window.addEventListener('click', (event) => {
            const name = event.target.getAttribute('name');
            if (name === 'contact') {
                document.getElementById("privacy-link-sidebar").style.height = null;
            this.setState({openPrivacyPage: false});
            this.closeNav();
            }
        })
        

    }

    componentDidUpdate () {
        const checkbox1 = document.getElementById('checkbox1'); 

        if (checkbox1 && checkbox1.checked) {
            if (document.getElementById('submit-button').hasAttribute('disabled')) {

                document.getElementById('submit-button').removeAttribute('disabled');
            } 
            
        } 
        else if (!document.getElementById('submit-button').hasAttribute('disabled')) {
            
            document.getElementById('submit-button').setAttribute('disabled', true);
            // document.getElementById('submit-button').disabled = true;

        }
    }

    openContactForm = () => {

        document.getElementById("contact-sidebar").style.height = "876px";
        

    }
    
    openPrivacyPolicyLink = () => {
        const checkbox1 = document.getElementById('checkbox1');
        console.log(checkbox1.checked)
        this.setState({openPrivacyPage: true});


        if( window.innerWidth >= 1500) {
    
            document.getElementById("privacy-link-sidebar").style.height = "560px";
        } else if (window.innerWidth >= 650) {

            document.getElementById("privacy-link-sidebar").style.height = "520px";
        } else if (window.innerWidth >= 500){

            document.getElementById("privacy-link-sidebar").style.height = "565px";
        }else if (window.innerWidth > 400) {

            document.getElementById("privacy-link-sidebar").style.height = "500px";
        } else {
            document.getElementById("privacy-link-sidebar").style.height = "525px";

        }
        

    }

    closeNav = () => {
        if (this.state.openPrivacyPage) {
            document.getElementById("privacy-link-sidebar").style.height = null;
            this.setState({openPrivacyPage: false})
        } else {

            document.getElementById("contact-sidebar").style.height = '0px';
        }
    }

    handleSubmit = event => {
        
        const templateId = 'template_IEmwa67n';
        const checkbox1 = document.getElementById('checkbox1');
        // const checkbox2 = document.getElementById('checkbox2');
        // this.setState({checkbox1: document.getElementById('checkbox1')})
        // this.setState({checkbox2: document.getElementById('checkbox2')})

        if ( checkbox1.checked) {

            this.sendFeedback(templateId,
                 {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    phone_number: this.state.phoneNumber,
                    country: this.state.country,
                    text_message: this.state.textMessage
    
                    }
                 );
                    this.setState({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phoneNumber: '',
                        country: '',
                        textMessage: '',
                    });
                    this.setState({checkbox1: checkbox1.checked
                        // checkbox2: checkbox2.checked
                    })
                    checkbox1.checked = false;
                    // checkbox2.checked = false;

                 document.getElementById("contact-sidebar").style.height = '0px';
        }

    }

    sendFeedback = (templateId, variables) => {
        window.emailjs.send(
          'gmail', templateId,
          variables
          ).then(res => {
            console.log('Email successfully sent!')
          })
          // Handle errors here however you like, or use a React error boundary
          .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
      }


    contactUsForm = () => {
        return (
            <div id="contact-sidebar" className="sidebar">
                    <div className="sidebar-bg">
                        <div id="sidebar-main-title" className="sidebar-title">GET IN TOUCH</div>
                        <div >
                            <form className="sidebar-form">

                                <div className="each-form">
                                    <div className="sidebar-name-bg">

                                        <label className="sidebar-name" >First Name</label>
                                    </div>
                                    <input 
                                        className="sidebar-input"
                                        placeholder="First Name"
                                        onChange={(event) => this.setState({firstName: event.target.value})} 
                                        type="text"
                                        value={this.state.firstName}
                                      />
                                </div>
                                <div className="each-form">
                                    <div className="sidebar-name-bg">

                                        <label className="sidebar-name" >Last Name</label>
                                    </div>
                                    <input 
                                        className="sidebar-input"
                                        placeholder="Last Name"
                                        onChange={(event) => this.setState({lastName: event.target.value})} 

                                        type="text"
                                        value={this.state.lastName}
                                      />
                                </div>
                                <div className="each-form">
                                    <div className="sidebar-name-bg">

                                        <label className="sidebar-name" >Email</label>
                                    </div>
                                    <input 
                                        className="sidebar-input"
                                        placeholder="Email"
                                        onChange={(event) => this.setState({email: event.target.value})} 
 
                                        type="text"
                                        value={this.state.email}
                                      />
                                </div>
                                <div className="each-form">
                                    <div className="sidebar-name-bg">

                                        <label className="sidebar-name" >Phone Number</label>
                                    </div>
                                    <input 
                                        className="sidebar-input"
                                        placeholder="Optional"
                                        onChange={(event) => this.setState({phoneNumber: event.target.value})} 
                                        type="text"
                                        value={this.state.phoneNumber}
                                      />
                                </div>
                                <div className="each-form">
                                    <div className="sidebar-name-bg">

                                        <label className="sidebar-name" >Country</label>
                                    </div>
                                    <input 
                                        className="sidebar-input"
                                        onChange={(event) => this.setState({country: event.target.value})} 
                                        type="text"
                                        value={this.state.country}
                                      />
                                </div>
                                <div className="each-form">
                                    <div className="sidebar-name-bg">

                                        <label className="sidebar-name"  style={{color: '#bbbebf'}}>Your message here</label>
                                    </div>
                                    <input 
                                        className="sidebar-input"
                                        onChange={(event) => this.setState({textMessage: event.target.value})} 
                                        type="text"
                                        value={this.state.textMessage}
                                      />
                                </div>
                            </form>
                        </div>

                        <div className="sidebar-privacy">
                            By filling out this form, you agree and be willing to allow us to
                             collect your contact details and other information
                              that may be required to enable us to effectively and
                               efficiently reply to all your enquiries. 
                            For the full information on how we protect and manage
                             your details, do check out our <Link to='' onClick={this.openPrivacyPolicyLink} className="privacy-link">Privacy Policy.</Link> 

                        </div>
                        <div id="privacy-link-sidebar" className="sidebar-privacy-policy">
                            <PrivacyPolicyPage/>

                        </div>

                        <div className="sidebar-checkbox">
                            <label className="sidebar-container">
                                {`Yes, I agree to the Privacy Policy *.`}
                                <input id="checkbox1" onClick={event => this.setState({checkbox1: event.target.checked})} type="checkbox"/>
                                <span className="checkmark"></span>
                            </label>
                            {/* <label className="sidebar-container">
                                {`I agree to receive marketing promotional materials and news tailored to me that is related to your products and services via email.`}
                                <input id="checkbox2" onClick={event => this.setState({checkbox2: event.target.checked})} type="checkbox"/>
                                <span className="checkmark"></span>
                            </label> */}
                        </div>

                    <div  className="submit-button" id="submit-button" disabled value="Submit" onClick={this.handleSubmit}>SUBMIT ENQUIRE</div>

                        

                    <div  className="closebtn" onClick={this.closeNav}>×</div>
                    </div>
                    
                </div>
        )
    }

    render() {

        if (!this.props.footer.length) {
            return (<div>Loading..</div>)
        }

        return (
            <div>

                <div id="footer" style={{opacity: '1'}}>
                    <div className="footer-title">
                        {this.props.footer[0].title} 
                    </div>
                    <div className="footer-text">
                        {this.props.footer[0].description} 
                    </div>
                    <button className="footer-button" onClick={this.openContactForm}>GET IN TOUCH</button>
                    <div className="footer-end">
                        {this.props.footer[0].location} 

                    </div>

                </div>
                
                {this.contactUsForm()}
                
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return  {
        footer: Object.values(state.footer)
    }
}
                    
export default connect(mapStateToProps, {fetchFooterSection})(Footer);