import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import HeaderPages from './HeaderPages';
import history from '../history';

import MainPage from './MainPage';

import GameView from './HeaderItems/GameView'
import NewsView from './HeaderItems/NewsView'
import './App.css';

class App extends React.Component {
    render() {

        return (
            <div id="page-contact" className="container" >
                <Router history={history} >
                        <div>
                            <HeaderPages/>
                            <Switch>
                                <Route path="/" exact component={MainPage} />
                                <Route path="/games/:id"   component={GameView} />
                                <Route path="/news"  component={NewsView} />
                            </Switch>
                        </div>
                </Router>
            </div>
        )
    }
}

export default App;
