import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import {ParallaxProvider} from 'react-scroll-parallax';




// npm install redux-form@8.1.0

import App from './components/App';
import reducers from './reducers';




const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(reduxThunk))
);

ReactDOM.render(
    <Provider store={store}>

        <ParallaxProvider >
            <App  />
        </ParallaxProvider>
    </Provider>,
    document.querySelector('#root1')
);

