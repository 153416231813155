import React from 'react';
import GamePage from './HeaderItems/GamesPage';
import GamesSection from './HeaderItems/GamesSection';
import CompanyPage from './HeaderItems/CompanyPage';
import './App.css';
import AboutPage from './HeaderItems/AboutPage';
import AboutCompanyPage from './HeaderItems/AboutCompanyPage';
import ContactPage from './HeaderItems/ContactPage';
import HomePage from './HeaderItems/HomePage';
import Footer from './Footer';


const MainPage = () => {
    return (
        <div>
            <HomePage/>
            <AboutCompanyPage/>
            <CompanyPage/>
            <AboutPage/>
            <GamePage/>
            <GamesSection/>
            <ContactPage/>
            <div style={{backgroundColor: 'white'}}>

                <Footer/>
            </div>

        </div>
    )
}

export default MainPage;