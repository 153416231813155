import React from 'react';
import {connect} from 'react-redux';

import {fetchAboutPage} from '../../actions'
import './allCss/AboutPage.css';

import separator_main from '../../assets/svg_files/separator_main.svg'



class AboutPage extends React.Component {

    componentDidMount = () => {
        this.props.fetchAboutPage();
    }

    renderTexts = (textNumber) => {
        switch (textNumber) {
            case 1:
                return (
                    <div className="section-texts">
        
                        <div className="section-title ">
                            {this.props.abouts[0].inspirationalTitle}
                        </div>
                        {this.renderSeparator()}

                        <div id="description">
                            {this.props.abouts[0].inspirationalDescription}
                        </div>
                    </div>

                    
                );
            case 2:
                return (
                    
                    <div className="section-texts">
        
                        <div className="section-title ">
                            {this.props.abouts[0].creativeTitle}
                        </div>
                        {this.renderSeparator()}

                        <div id="description">
                            {this.props.abouts[0].creativeDescription}
                        </div>
                    </div>
                );
            case 3:
                return (
                    
                    <div className="section-texts">
        
                        <div className="section-title ">
                            {this.props.abouts[0].professionalTitle}
                        </div>
                        {this.renderSeparator()}

                        <div id="description">
                            {this.props.abouts[0].professionalDescription}
                        </div>
                    </div>
                );
            default:

        }
    }

    renderSeparator = () => {
        return (
            <div style={{display: '-webkit-flex',display: 'flex', padding: '30px 0 20px 0' }} >

                    <svg height="8px" width="100%">
                      <line x1="0%" y1="8px" x2="94%" y2="8px" style={{stroke:'#dc3847',strokeWidth:2}} />
                    </svg>
                    <embed className="separator"  alt='separator' src={separator_main}/>
                    <svg height="8px" width="100%">
                      <line x1="6%" y1="8px" x2="100%" y2="8px" style={{stroke:'rgb(220,56,71)',strokeWidth:2}} />
                    </svg>
                </div>
        )
    }

    render() {
        if (!this.props.abouts.length) {
            return <div>Loading...</div>
        }

        return (
            <div   id="about-page">
                <div style={{position: "relative", display: 'flex', }}>
                    <div className="about-page-section">

                        <div className="section-column" >
                            <div className="section-column-container">
                                <embed className="section-img" alt='inspirationalPng' src={this.props.abouts[0].inspirationalPng}/>
                                {this.renderTexts(1) }
                            </div>
                        </div>

                        <div className="section-column"  >
                            <div  className="section-column-container">
                                <embed className="section-img"  alt='creativePng' src={this.props.abouts[0].creativePng}/>
                                {this.renderTexts(2) }
                            </div>
                        </div>
                        
                        <div className=" section-column" >
                            <div  className="section-column-container">
                                <embed className="section-img"  alt='professionalPng' src={this.props.abouts[0].professionalPng}/>
                                {this.renderTexts(3) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        abouts:  Object.values(state.aboutPage)
    }
}

export default connect(mapStateToProps, {fetchAboutPage})(AboutPage);
